import {
  unstable_createMuiStrictModeTheme as createMuiTheme,
  lighten,
} from '@material-ui/core/styles';
import merge from 'lodash/merge';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#ff6600',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#2e8bc9',
    },
  },
  props: {
    MuiButton: {
      variant: 'contained',
      color: 'primary',
    },
    MuiFormHelperText: {
      error: true,
    },
    MuiGrid: {
      spacing: 4,
    },
    MuiIconButton: {
      color: 'secondary',
    },
    MuiTextField: {
      margin: 'normal',
      fullWidth: true,
    },
  },
  typography: {
    fontSize: 16,
    h1: {
      fontWeight: 'normal',
      fontSize: '1.125rem',
    },
    h2: {
      fontWeight: 'bold',
      fontSize: '2.375rem',
    },
    h3: {
      fontWeight: 'bold',
      fontSize: '1.5rem',
    },
  },
});

const STANDARD_SPACING = theme.spacing(4);

merge(theme, {
  layout: {
    standardSpacing: STANDARD_SPACING,
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        body: {
          minWidth: theme.breakpoints.values.md,
        },
        table: {
          width: '100%',
          borderCollapse: 'collapse',
        },
      },
    },
    MuiDialog: {
      paper: {
        minWidth: theme.breakpoints.values.sm,
      },
    },
    MuiDialogActions: {
      root: {
        padding: theme.spacing(1, 3, 3),
      },
    },
    MuiPickersDay: {
      daySelected: {
        backgroundColor: theme.palette.secondary.main,
      },
    },
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: theme.palette.secondary.main,
      },
    },
    MuiTableContainer: {
      root: {
        fontSize: '0.8rem',
      },
    },
    MuiTableRow: {
      head: {
        backgroundColor: theme.palette.grey[200],
      },
    },
    MuiTableCell: {
      root: {
        border: `1px solid ${theme.palette.grey[300]}`,
        padding: theme.spacing(0, 2),
      },
      head: {
        padding: theme.spacing(1, 2),
      },
    },
    MuiSwitch: {
      root: {
        width: 42,
        height: 26,
        padding: 0,
        marginLeft: 10,
        '& .MuiSwitch-switchBase': {
          padding: 0,
          margin: 2,
          transitionDuration: '300ms',
          '&.Mui-checked': {
            transform: 'translateX(16px)',
            color: theme.palette.primary.contrastText,
            '& + .MuiSwitch-track': {
              backgroundColor: theme.palette.secondary.main,
              opacity: 1,
              border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
              opacity: 0.2,
            },
          },
          '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: theme.palette.secondary.main,
            border: `6px solid ${theme.palette.primary.contrastText}`,
          },
          '&.Mui-disabled .MuiSwitch-thumb': {
            color: theme.palette.primary.contrastText,
          },
          '&.Mui-disabled + .MuiSwitch-track': {
            opacity: 0.2,
          },
        },
        '& .MuiSwitch-thumb': {
          boxSizing: 'border-box',
          width: 22,
          height: 22,
        },
        '& .MuiSwitch-track': {
          borderRadius: 13,
          backgroundColor: theme.palette.grey[300],
          opacity: 1,
          transition: theme.transitions.create(['background-color'], {
            duration: 500,
          }),
        },
      },
    },
  },
  palette: {
    primary: {
      lighter: lighten(
        theme.palette.primary.main,
        theme.palette.tonalOffset as number * 4,
      ),
    },
    secondary: {
      lighter: lighten(
        theme.palette.secondary.main,
        theme.palette.tonalOffset as number * 4,
      ),
    },
    button: {
      green: '#0DC763',
    },
  },
  typography: {
    h2: {
      marginBottom: `${STANDARD_SPACING}px`,
    },
    h3: {
      marginBottom: `${STANDARD_SPACING}px`,
    },
  },
});

export default theme;
