import { configureStore, createSelector } from '@reduxjs/toolkit';
import find from 'lodash/find';
import orderBy from 'lodash/orderBy';
import pick from 'lodash/pick';
import { combineReducers } from 'redux';

import alertReducer from './alertReducer';
import exportsReducer from './exportsReducer';
import fieldsReducer from './fieldsReducer';
import {
  AlertState,
  ExportsState,
  FieldsState,
  MerchantsState,
  QueriesState,
  RegionsState,
  UsersState,
} from './interfaces';
import merchantsReducer from './merchantsReducer';
import queriesReducer from './queriesReducer';
import regionsReducer from './regionsReducer';
import usersReducer from './usersReducer';

interface RootState {
  alert: AlertState,
  fields: FieldsState,
  merchants: MerchantsState,
  queries: QueriesState,
  regions: RegionsState,
  users: UsersState,
  exports: ExportsState;
}

const reducer = combineReducers({
  alert: alertReducer,
  fields: fieldsReducer,
  merchants: merchantsReducer,
  queries: queriesReducer,
  regions: regionsReducer,
  users: usersReducer,
  exports: exportsReducer,
});

const store = configureStore({ reducer });

export type AppDispatch = typeof store.dispatch;

export const merchantsSelector = (state: RootState) => state.merchants;

export const regionsSelector = (state: RootState) => state.regions;

export const regionSelector = (regionId: string | null) => createSelector(
  regionsSelector,
  (regions: RegionsState) => (regionId === null ? null : regions[regionId] || null),
);

export const merchantSelector = (merchantId: string) => createSelector(
  merchantsSelector,
  (merchants: MerchantsState) => merchants[merchantId],
);

export const usersSelector = (state: RootState) => state.users;

const fieldsSelector = (state: RootState) => state.fields;

export const merchantFieldsSelector = (merchantId: string) => createSelector(
  merchantSelector(merchantId),
  fieldsSelector,
  (merchant, fields) => (
    merchant.fieldIds ? pick(fields, merchant.fieldIds) : null
  ),
);

export const queriesSelector = (state: RootState) => state.queries;

export const regionsLoadedSelector = createSelector(
  queriesSelector,
  (queries: QueriesState) => queries.regionsEndOfList,
);

export const regionsPendingSelector = createSelector(
  queriesSelector,
  (queries: QueriesState) => queries.regionsPending,
);

export const merchantsLoadedSelector = createSelector(
  queriesSelector,
  (queries: QueriesState) => queries.merchantsEndOfList,
);

export const merchantsPendingSelector = createSelector(
  queriesSelector,
  (queries: QueriesState) => queries.merchantsPending,
);

export const usersLoadedSelector = createSelector(
  queriesSelector,
  (queries: QueriesState) => queries.usersEndOfList,
);

export const usersPendingSelector = createSelector(
  queriesSelector,
  (queries: QueriesState) => queries.usersPending,
);

const exportsSelector = (state: RootState) => state.exports;

export const mostRecentExportSelector = (
  userId: string,
  merchantId: string | null,
) => createSelector(
  exportsSelector,
  (exports: ExportsState) => find(
    orderBy(exports, ['createdAt'], ['desc']),
    (exp) => exp.userId === userId && exp.merchantId === merchantId,
  ) || null,
);

export const alertSelector = (state: RootState) => state.alert;

export default store;
