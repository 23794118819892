import { Role } from 'linebuster-types';

export enum RoutePath {
  HOME = '/',
  CUSTOM_FIELDS = '/custom-fields',
  EXPORT = '/export',
  LOGIN = '/login',
  MERCHANTS = '/regions/:regionId/merchants',
  USER_MANAGEMENT = '/user-management',
  SET_PASSWORD = '/confirm-password',
  FORGOT_PASSWORD = '/forgot-password',
  REGIONS = '/regions',
}

export const routeRoles: { [key: string]: Role[]} = {
  [RoutePath.CUSTOM_FIELDS]: [Role.Admin, Role.Manager],
  [RoutePath.EXPORT]: [Role.Admin, Role.Manager],
  [RoutePath.LOGIN]: [],
  [RoutePath.MERCHANTS]: [Role.Admin],
  [RoutePath.REGIONS]: [Role.Admin],
  [RoutePath.USER_MANAGEMENT]: [Role.Admin],
  [RoutePath.SET_PASSWORD]: [],
  [RoutePath.FORGOT_PASSWORD]: [],
};

export const landingPages: { [key: string]: string } = {
  [Role.Admin]: RoutePath.CUSTOM_FIELDS,
  [Role.Manager]: RoutePath.CUSTOM_FIELDS,
};
