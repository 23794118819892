import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import PropTypes, { InferProps } from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const RedButton = styled(IconButton)`
  color: ${({ theme }) => theme.palette.error.main};
`;

const propTypes = {
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

const CancelButton = ({ onClick, disabled }: InferProps<typeof propTypes>) => (
  <RedButton onClick={onClick} disabled={disabled!}>
    <CloseIcon />
  </RedButton>
);

CancelButton.propTypes = propTypes;
CancelButton.defaultProps = {
  disabled: false,
};

export default CancelButton;
