import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes, { InferProps } from 'prop-types';
import React, { useState } from 'react';
import styled from 'styled-components';

const bulkModalPropTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  settingName: PropTypes.string.isRequired,
};

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const BulkModal = ({
  visible, onClose, onConfirm, settingName,
}: InferProps<typeof bulkModalPropTypes>) => {
  const [bulkUpdateIsLoading, setBulkLoading] = useState<boolean>(false);
  const onConfirmClicked = async () => {
    setBulkLoading(true);
    await onConfirm();
    setBulkLoading(false);
    onClose();
  };

  return (
    <Dialog open={visible}>
      <DialogTitle>
        Are you sure?
      </DialogTitle>
      <DialogContent>
        {!bulkUpdateIsLoading && (
        <p>
          {`Doing this will overwrite existing ${settingName} settings of all other merchants in the region.`}
        </p>
        )}

        {bulkUpdateIsLoading && <LoadingContainer><CircularProgress color="secondary" /></LoadingContainer>}
      </DialogContent>
      <DialogActions>
        <Button color="default" onClick={onClose} disabled={bulkUpdateIsLoading}>Cancel</Button>
        <Button onClick={onConfirmClicked} disabled={bulkUpdateIsLoading}>Confirm</Button>
      </DialogActions>
    </Dialog>
  );
};

export default BulkModal;
