import { User } from 'linebuster-types';
import PropTypes, { InferProps } from 'prop-types';
import React, { useState } from 'react';

import { routeRoles } from '../../config/routes';
import { UserContext } from './index';

const propTypes = {
  children: PropTypes.node.isRequired,
};

const UserContextProvider = ({ children }: InferProps<typeof propTypes>) => {
  const [user, setUser] = useState<User | null | undefined>(undefined);

  const hasPermission = (path: string): boolean | undefined => {
    if (user === undefined) {
      return undefined;
    }
    if (user === null) {
      return false;
    }
    return routeRoles[path].includes(user.role);
  };

  return (
    <UserContext.Provider value={{ user, setUser, hasPermission }}>
      {children}
    </UserContext.Provider>
  );
};

UserContextProvider.propTypes = propTypes;

export default UserContextProvider;
