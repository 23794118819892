import { createReducer } from '@reduxjs/toolkit';
import { Export } from 'linebuster-types';

import {
  getMostRecentExport,
  createExport,
  observeExport,
} from './actions';
import { ExportsState } from './interfaces';

const addExport = (
  state: ExportsState,
  { payload }: { payload: { export: { [key: string]: any } } | null },
) => {
  if (payload === null) {
    return state;
  }

  const { export: exp } = payload;
  return ({
    ...state,
    [exp.id]: exp as Export,
  });
};

const exportsReducer = createReducer<ExportsState>({}, (builder) => {
  builder
    .addCase(
      getMostRecentExport.fulfilled,
      addExport,
    )
    .addCase(
      observeExport.fulfilled,
      addExport,
    )
    .addCase(
      createExport.fulfilled,
      (state, { payload: { export: exp } }) => ({
        ...state,
        [exp.id]: exp as Export,
      }),
    );
});

export default exportsReducer;
