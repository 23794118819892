import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { ExportStatus } from 'linebuster-types';
import { DateTime } from 'luxon';
import PropTypes, { InferProps } from 'prop-types';
import React, { useEffect } from 'react';
import styled from 'styled-components';

import {
  useMostRecentExport,
  useMerchant,
  useObserveExport,
  usePrevious,
} from '../../../store/hooks';
import Spinner from '../../lib/Spinner';

const SpinnerContainer = styled.div`
  margin-top: ${({ theme }) => theme.layout.standardSpacing}px;
`;

const ExportTable = styled(Table)`
  margin-top: ${({ theme }) => theme.layout.standardSpacing}px;
`;

const DownloadButton = styled(Button).attrs({ color: 'secondary', download: true })`
  margin: ${({ theme }) => theme.layout.standardSpacing / 2}px 0;
`;

const merchantNamePropTypes = {
  merchantId: PropTypes.string.isRequired,
};

const MerchantName = ({ merchantId }: InferProps<typeof merchantNamePropTypes>) => {
  const { name } = useMerchant(merchantId);
  return <>{name}</>;
};

const formatDate = (date: Date) => DateTime.fromISO(date as unknown as string).toLocaleString();

const propTypes = {
  userId: PropTypes.string.isRequired,
  merchantId: PropTypes.string,
  stopLoading: PropTypes.func.isRequired,
};

const ExportFile = ({
  userId,
  merchantId,
  stopLoading,
}: InferProps<typeof propTypes>) => {
  const [exp, getExport] = useMostRecentExport(userId, merchantId as string | null);
  const observeExport = useObserveExport();
  const prevStatus = usePrevious(exp ? exp.status : null);

  useEffect(() => {
    getExport();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [merchantId]);

  useEffect(() => {
    if (exp !== null && exp.status !== prevStatus) {
      if (exp.status === ExportStatus.Pending) {
        observeExport(exp.id);
      } else {
        stopLoading();
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exp, prevStatus]);

  if (exp === null) {
    return null;
  }

  if (exp.status === ExportStatus.Pending) {
    return (
      <SpinnerContainer>
        <Spinner />
      </SpinnerContainer>
    );
  }

  return (
    <ExportTable>
      <TableHead>
        <TableRow>
          <TableCell>Merchant</TableCell>
          <TableCell>Start Date</TableCell>
          <TableCell>End Date</TableCell>
          <TableCell />
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell>
            {merchantId ? <MerchantName merchantId={exp.merchantId} /> : 'All merchants'}
          </TableCell>
          <TableCell>{formatDate(exp.startDate)}</TableCell>
          <TableCell>{formatDate(exp.endDate)}</TableCell>
          <TableCell>
            <DownloadButton href={exp.url}>
              Download
            </DownloadButton>
          </TableCell>
        </TableRow>
      </TableBody>
    </ExportTable>
  );
};

ExportFile.propTypes = propTypes;
ExportFile.defaultProps = {
  merchantId: null,
};

export default ExportFile;
