/* eslint-disable react/jsx-props-no-spreading */

import MaterialLink, { LinkProps as MaterialLinkProps } from '@material-ui/core/Link';
import React from 'react';
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom';

const Link = (props: MaterialLinkProps & RouterLinkProps) => (
  <MaterialLink component={RouterLink} {...props} />
);

export default Link;
