import LinearProgress from '@material-ui/core/LinearProgress';
import { useTheme } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import { Field } from 'linebuster-types';
import PropTypes, { InferProps } from 'prop-types';
import React, { useEffect, useState } from 'react';

import { useFields, usePrevious } from '../../../store/hooks';
import IconItem from '../../lib/IconItem';
import FieldForm from './FieldForm';

const propTypes = {
  merchantId: PropTypes.string.isRequired,
};

const FieldsForm = ({ merchantId }: InferProps<typeof propTypes>) => {
  const theme = useTheme();
  const [hasNew, setHasNew] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [fields, getFields] = useFields(merchantId);
  const prevFieldsLength = usePrevious(fields === null ? 0 : fields.length);

  useEffect(() => {
    getFields();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [merchantId]);

  useEffect(() => {
    if (fields !== null && fields.length !== prevFieldsLength) {
      setIsLoading(false);
    }
  }, [prevFieldsLength, fields]);

  if (fields === null) {
    return <LinearProgress />;
  }
  return (
    <>
      {isLoading && <LinearProgress />}
      <table>
        <tbody>
          {fields.map((field) => (
            <FieldForm
              key={field.id}
              merchantId={merchantId}
              field={new Field(field)}
              startLoading={() => setIsLoading(true)}
            />
          ))}
          {hasNew && (
            <FieldForm
              merchantId={merchantId}
              field={new Field({
                isLocked: false,
                order: fields.length,
                currentVersion: {
                  prompt: '',
                },
              })}
              closeNew={() => setHasNew(false)}
              startLoading={() => setIsLoading(true)}
            />
          )}
        </tbody>
      </table>
      {!hasNew && (
        <IconItem
          text="Add a field"
          color={theme.palette.secondary.main}
          IconClass={AddIcon}
          onClick={() => setHasNew(true)}
        />
      )}
    </>
  );
};

FieldsForm.propTypes = propTypes;

export default FieldsForm;
