import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import PropTypes, { InferProps } from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.layout.standardSpacing / 2}px;
`;

const Heading = styled(Typography).attrs(() => ({ variant: 'h3' }))`
  display: inline-block;
  margin-bottom: 0;
`;

const LeftComponent = styled(Grid)`
  display: flex;
  align-items: center;
`;
const propTypes = {
  label: PropTypes.string.isRequired,
  onAdd: PropTypes.func,
  onRemove: PropTypes.func,
  RightComponent: PropTypes.node,
};

const TableHeading = ({
  label,
  onAdd,
  onRemove,
  RightComponent,
}: InferProps<typeof propTypes>) => (
  <Container>
    <LeftComponent item xs={8}>
      <Heading>{label}</Heading>
      {onAdd !== null && (
      <IconButton onClick={onAdd}>
        <AddCircleIcon />
      </IconButton>
      )}
      {onRemove !== null && (
      <IconButton onClick={onRemove}>
        <RemoveCircleIcon />
      </IconButton>
      )}
    </LeftComponent>
    <Grid item xs={4}>
      {RightComponent}
    </Grid>
  </Container>
);

TableHeading.propTypes = propTypes;
TableHeading.defaultProps = {
  onAdd: null,
  onRemove: null,
  RightComponent: null,
};

export default TableHeading;
