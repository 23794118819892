import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import TextField from '@material-ui/core/TextField';
import { useFormik } from 'formik';
import queryString from 'query-string';
import React, { useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { setPassword as setPasswordAction } from '../../../store/actions';
import { useSetError, useSetPassword } from '../../../store/hooks';

const SetPasswordContainer = styled(Grid)`
  justify-content: center;
`;

const ConfirmButton = styled(Button).attrs(() => ({
  fullWidth: true,
}))``;

interface FormState {
  passwordConfirmation: string,
  password: string,
}

const SetPassword = () => {
  const setError = useSetError();
  const location = useLocation();
  const history = useHistory();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const setPassword = useSetPassword();
  const validate = ({ passwordConfirmation, password }: FormState) => {
    const errors: { passwordConfirmation?: string, password?: string } = {};
    if (passwordConfirmation === '') {
      errors.passwordConfirmation = 'Password Confirmation required';
    }
    if (password === '') {
      errors.password = 'Password required';
    }
    if (password !== passwordConfirmation) {
      errors.password = 'Passwords do not match';
    }
    return errors;
  };

  const submit = async ({ password }: FormState) => {
    const params: any = queryString.parse(location.search);
    if (!params || !params.token) {
      return setError('No token present.');
    }
    setIsSubmitting(true);

    const resultAction = await setPassword(params.token, password);
    if (setPasswordAction.fulfilled.match(resultAction)) {
      history.push('/login');
    }

    setIsSubmitting(false);

    return {};
  };

  const {
    values: { passwordConfirmation, password },
    errors: { passwordConfirmation: passwordConfirmationError, password: passwordError },
    handleChange,
    handleSubmit,
  } = useFormik<FormState>({
    initialValues: { passwordConfirmation: '', password: '' },
    validate,
    onSubmit: submit,
  });

  return (
    <>
      {isSubmitting && <LinearProgress color="secondary" /> }
      <SetPasswordContainer container>
        <Grid item xs={4}>
          <form onSubmit={handleSubmit}>
            <Card>
              <CardContent>
                <TextField
                  label="Password"
                  name="password"
                  type="password"
                  value={password}
                  onChange={handleChange}
                  helperText={passwordError}
                />
                <TextField
                  label="Password Confirmation"
                  name="passwordConfirmation"
                  type="password"
                  value={passwordConfirmation}
                  onChange={handleChange}
                  helperText={passwordConfirmationError}
                />
              </CardContent>
              <CardActions>
                <ConfirmButton type="submit">
                  Confirm
                </ConfirmButton>
              </CardActions>
            </Card>
          </form>
        </Grid>
      </SetPasswordContainer>
    </>
  );
};

export default SetPassword;
