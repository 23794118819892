import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import {
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';

import { landingPages, RoutePath } from '../../config/routes';
import { UserContext } from '../contexts';
import CustomFields from '../features/custom-fields/CustomFields';
import ExportForm from '../features/export/ExportForm';
import ForgotPassword from '../features/login/ForgotPassword';
import Login from '../features/login/Login';
import SetPassword from '../features/login/SetPassword';
import Merchants from '../features/merchants/Merchants';
import Regions from '../features/regions/Regions';
import UserManagement from '../features/user-management/UserManagement';
import AuthRoute from './AuthRoute';

const propTypes = {
  customFieldsTabDisabled: PropTypes.bool.isRequired,
};
interface RoutesState {
  customFieldsTabDisabled: boolean,
}

const Routes = ({ customFieldsTabDisabled }: RoutesState) => {
  const { user } = useContext(UserContext);
  const renderRedirectOrLoginRoute = () => {
    if (user != null) {
      if (customFieldsTabDisabled) {
        return <Redirect to={RoutePath.EXPORT} />;
      }
      return <Redirect to={landingPages[user.role]} />;
    }
    return <Login />;
  };

  return (
    <Switch>
      <Route path={RoutePath.SET_PASSWORD} exact>
        <SetPassword />
      </Route>
      <Route path={RoutePath.FORGOT_PASSWORD} exact>
        <ForgotPassword />
      </Route>
      <Route path={[RoutePath.HOME, RoutePath.LOGIN]} exact>
        {renderRedirectOrLoginRoute}
      </Route>
      <AuthRoute path={RoutePath.CUSTOM_FIELDS}>
        <CustomFields />
      </AuthRoute>
      <AuthRoute path={RoutePath.EXPORT}>
        <ExportForm />
      </AuthRoute>
      <AuthRoute path={RoutePath.REGIONS} exact>
        <Regions />
      </AuthRoute>
      <AuthRoute path={RoutePath.MERCHANTS}>
        <Merchants />
      </AuthRoute>
      <AuthRoute path={RoutePath.USER_MANAGEMENT}>
        <UserManagement />
      </AuthRoute>
      <Route path="*">
        <div>Page not found</div>
      </Route>
    </Switch>
  );
};

Routes.propTypes = propTypes;

export default Routes;
