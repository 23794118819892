import { AnyAction, createReducer } from '@reduxjs/toolkit';
import size from 'lodash/size';

import { QUERY_LIMIT } from '../config/constants';
import {
  getRegions,
  getMerchants,
  getUsers,
} from './actions';
import { QueriesState } from './interfaces';

const queriesReducer = createReducer<QueriesState>({
  merchantsPending: false,
  merchantsEndOfList: false,
  regionsPending: false,
  regionsEndOfList: false,
  usersPending: false,
  usersEndOfList: false,
}, (builder) => {
  builder
    .addCase(
      getRegions.fulfilled,
      (state, { payload: { regions } }) => {
        const nextState = {
          ...state,
          regionsPending: false,
        };
        if (size(regions) < QUERY_LIMIT) {
          return {
            ...nextState,
            regionsEndOfList: true,
          };
        }
        return nextState;
      },
    )
    .addCase(
      getMerchants.fulfilled,
      (state, { payload: { replace, merchants } }) => {
        const nextState = {
          ...state,
          merchantsPending: false,
        };
        if (replace) {
          return {
            ...nextState,
            merchantsEndOfList: false,
          };
        }
        if (size(merchants) < QUERY_LIMIT) {
          return {
            ...nextState,
            merchantsEndOfList: true,
          };
        }
        return nextState;
      },
    )
    .addCase(
      getUsers.pending,
      (state) => ({
        ...state,
        usersPending: true,
      }),
    )
    .addCase(
      getUsers.fulfilled,
      (state, { payload: { users } }) => {
        const nextState = {
          ...state,
          usersPending: false,
        };
        if (size(users) < QUERY_LIMIT) {
          return {
            ...nextState,
            usersEndOfList: true,
          };
        }
        return nextState;
      },
    )
    .addMatcher(
      (action): action is AnyAction => action.type.startsWith('regions/'),
      (state, action) => ({
        ...state,
        regionsPending: action.type.endsWith('/pending'),
      }),
    )
    .addMatcher(
      (action): action is AnyAction => action.type.startsWith('merchants/'),
      (state, action) => ({
        ...state,
        merchantsPending: action.type.endsWith('/pending'),
      }),
    )
    .addMatcher(
      (action): action is AnyAction => action.type.startsWith('users/'),
      (state, action) => ({
        ...state,
        usersPending: action.type.endsWith('/pending'),
      }),
    );
});

export default queriesReducer;
