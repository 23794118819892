import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import { useFormik } from 'formik';
import { Region } from 'linebuster-types';
import isEmpty from 'lodash/isEmpty';
import PropTypes, { InferProps } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import {
  useRegion,
  useCreatedRegionId,
  useCreateRegion,
  useUpdateRegion,
  usePrevious,
} from '../../../store/hooks';
import Spinner from '../../lib/Spinner';

interface FormState {
  name?: string,
}

const propTypes = {
  regionId: PropTypes.string,
  close: PropTypes.func.isRequired,
};

const EditRegionModal = ({
  regionId,
  close,
}: InferProps<typeof propTypes>) => {
  const history = useHistory();
  const region = useRegion(regionId!);
  const createdRegionId = useCreatedRegionId();
  const createRegion = useCreateRegion();
  const updateRegion = useUpdateRegion();
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const prevRegionName = usePrevious(region ? region.name : null);

  const isNew = regionId === null;

  useEffect(() => {
    if (isUpdating) {
      if (isNew) {
        if (createdRegionId !== null) {
          history.push(`/regions/${createdRegionId}/merchants`);
        }
      } else if (region!.name !== prevRegionName) {
        close();
      }
    }
  }, [
    close,
    createdRegionId,
    history,
    isNew,
    isUpdating,
    prevRegionName,
    region,
  ]);

  const validate = ({ name }: FormState) => {
    const errors: {
      name?: string,
    } = {};

    if (name === '') {
      errors.name = 'Name is required';
    }

    return errors;
  };

  const regionInstance = new Region(region || {});
  const {
    values: {
      name,
    },
    errors: {
      name: nameError,
    },
    handleChange,
    handleSubmit,
    handleBlur,
    isValid,
    setFieldTouched,
    touched,
  } = useFormik<FormState>({
    initialValues: {
      name: regionInstance.name || '',
    },
    validate,
    onSubmit: () => {
      setIsUpdating(true);
      regionInstance.name = name!;

      if (isNew) {
        createRegion(regionInstance);
      } else {
        updateRegion(regionInstance);
      }
    },
  });

  return (
    <Dialog open onClose={close}>
      <DialogTitle>
        {`${isNew ? 'Create' : 'Edit'} Region`}
      </DialogTitle>
      <DialogContent>
        <TextField
          name="name"
          label="Region name"
          value={name}
          helperText={nameError}
          onBlur={handleBlur}
          onChange={handleChange}
          onInput={() => setFieldTouched('name')}
          disabled={isUpdating}
        />
      </DialogContent>
      <DialogActions>
        {isUpdating ? (
          <Spinner />
        ) : (
          <Button
            type="button"
            onClick={() => handleSubmit()}
            disabled={isEmpty(touched) || !isValid}
            fullWidth
          >
            {isNew ? 'Add Merchants' : 'Update'}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

EditRegionModal.propTypes = propTypes;
EditRegionModal.defaultProps = {
  regionId: null,
};

export default EditRegionModal;
