import Typography from '@material-ui/core/Typography';
import PropTypes, { InferProps } from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const Description = styled(Typography)`
  margin-bottom: ${({ theme }) => theme.layout.standardSpacing}px;
`;

const propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  children: PropTypes.node.isRequired,
};

const Dashboard = ({ title, description, children }: InferProps<typeof propTypes>) => (
  <>
    <Typography variant="h2">{title}</Typography>
    {description && <Description>{description}</Description>}
    {children}
  </>
);

Dashboard.propTypes = propTypes;
Dashboard.defaultProps = {
  description: null,
};

export default Dashboard;
