import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { useMerchants } from '../../../store/hooks';
import Page from '../../lib/Page';
import SmallContainer from '../../lib/SmallContainer';
import FieldsForm from './FieldsForm';

const MerchantDropdown = styled(FormControl)`
  margin-bottom: ${({ theme }) => theme.layout.standardSpacing}px;
`;

const CustomFieldsTitle = styled.span`
    font-weight: bold;
    font-size: 1.50rem;
    color: ${({ theme }) => theme.palette.primary.main};
`;

const CustomFieldsText = styled.span<{ customFieldsRequired: boolean}>`
  display: inline-flex;
  align-items: center;
  color: ${({ theme }) => theme.palette.primary.contrastText};
  background-color: ${({ theme, customFieldsRequired }) => (customFieldsRequired ? theme.palette.button.green : theme.palette.grey[600])};
  font-weight: bold;
  font-size: 1.25rem;
  padding: ${({ theme }) => theme.spacing(0.5)}px ${({ theme }) => theme.spacing(2)}px;
  margin-left: ${({ theme }) => theme.spacing(5)}px;
`;

const CustomFieldsDetail = styled.div`
  margin: ${({ theme }) => theme.layout.standardSpacing / 2}px;
`;

const CustomFieldsStatus = styled.div``;

interface Merchant {
  id: string,
  name: string,
  customFieldsRequired: boolean,
}

const CustomFields = () => {
  const [merchants, getMerchants] = useMerchants();
  const [merchant, setMerchant] = useState<Merchant>();

  useEffect(() => {
    getMerchants();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Page
      title="Custom Fields"
      description="You can include up to 5 custom fields in order to gather extra information from your customers during transactions. All fields will be text entry. The following fields will be showing to the user by default: Name, Address, Phone Number, and email."
    >
      <SmallContainer>
        <MerchantDropdown variant="outlined" fullWidth>
          <InputLabel>Merchant</InputLabel>
          <Select
            label="Merchant"
            name="merchant"
            value={JSON.stringify(merchant) || ''}
            onChange={(e) => setMerchant(JSON.parse(e.target.value as string))}
          >
            {merchants.map(
              (mObject) => (
                <MenuItem
                  key={mObject.id}
                  value={JSON.stringify({
                    id: mObject.id,
                    name: mObject.name,
                    customFieldsRequired: mObject.customFieldsRequired,
                  })}
                >
                  {mObject.name}
                </MenuItem>
              ),
            )}
          </Select>
        </MerchantDropdown>
        {merchant
        && (
        <>
          <>
            <CustomFieldsStatus>
              <CustomFieldsTitle>Custom Fields currently: </CustomFieldsTitle>
              <CustomFieldsText customFieldsRequired={merchant.customFieldsRequired}>
                {merchant.customFieldsRequired ? 'ON' : 'OFF'}
              </CustomFieldsText>
              <CustomFieldsStatus />
            </CustomFieldsStatus>
            <CustomFieldsDetail>
              Data collection of custom fields is
              {merchant.customFieldsRequired ? ' ' : ' not '}
              required for user during checkout.
            </CustomFieldsDetail>
          </>
          {merchant.customFieldsRequired && <FieldsForm merchantId={merchant.id} />}
        </>
        )}
      </SmallContainer>
    </Page>
  );
};

export default CustomFields;
