import LuxonUtils from '@date-io/luxon';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';
import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';

import './services/firestore';
import App from './App';
import UserContextProvider from './components/contexts/UserContextProvider';
import theme from './config/theme';
import store from './store';

ReactDOM.render(
  <StrictMode>
    <MuiPickersUtilsProvider utils={LuxonUtils}>
      <Provider store={store}>
        <MuiThemeProvider theme={theme}>
          <StyledThemeProvider theme={theme}>
            <CssBaseline />
            <UserContextProvider>
              <App />
            </UserContextProvider>
          </StyledThemeProvider>
        </MuiThemeProvider>
      </Provider>
    </MuiPickersUtilsProvider>
  </StrictMode>,
  document.getElementById('root'),
);
