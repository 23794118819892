import { Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {
  getRepository,
  CollectionId,
  MerchantRepository,
} from 'linebuster-types';
import PropTypes, { InferProps } from 'prop-types';
import React, { useEffect, useState } from 'react';

import { useDeleteRegion } from '../../../store/hooks';

const merchantRepository = getRepository<MerchantRepository>(CollectionId.Merchants);

const propTypes = {
  regionId: PropTypes.string.isRequired,
  close: PropTypes.func.isRequired,
};

const DeleteRegionModal = ({ regionId, close }: InferProps<typeof propTypes>) => {
  const deleteRegion = useDeleteRegion();
  const [merchantCount, setMerchantCount] = useState<number | null>(null);

  useEffect(() => {
    (async () => {
      const regionMerchants = await merchantRepository.findForRegion(regionId);
      setMerchantCount(regionMerchants.length);
    })();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (merchantCount === null) {
    return null;
  }

  if (merchantCount === 0) {
    deleteRegion(regionId);
    close();
    return null;
  }

  return (
    <Dialog open onClose={close}>
      <DialogTitle>Delete Region</DialogTitle>
      <DialogContent>
        <Typography>
          There are merchants in this region.
          Before deleting the region, please delete the merchants
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={close} variant="text">
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

DeleteRegionModal.propTypes = propTypes;

export default DeleteRegionModal;
