import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import firebase from 'firebase/app';
import { useFormik } from 'formik';
import React from 'react';
import styled from 'styled-components';

import logger from '../../../services/logger';
import { useSetError } from '../../../store/hooks';
import Link from '../../lib/Link';

const LoginContainer = styled(Grid)`
  justify-content: center;
`;

const LoginButton = styled(Button).attrs(() => ({
  fullWidth: true,
}))``;

interface FormState {
  email: string,
  password: string,
}

const Login = () => {
  const setError = useSetError();
  const validate = ({ email, password }: FormState) => {
    const errors: { email?: string, password?: string } = {};
    if (email === '') {
      errors.email = 'Email required';
    }
    if (password === '') {
      errors.password = 'Password required';
    }
    return errors;
  };

  const submit = async ({ email, password }: FormState) => {
    try {
      await firebase.auth().signInWithEmailAndPassword(email, password);
    } catch (error) {
      logger.error(error);
      setError('There was an error logging in');
    }
  };

  const {
    values: { email, password },
    errors: { email: emailError, password: passwordError },
    handleChange,
    handleSubmit,
  } = useFormik<FormState>({
    initialValues: { email: '', password: '' },
    validate,
    onSubmit: submit,
  });

  return (
    <>
      <LoginContainer container>
        <Grid item xs={4}>
          <form onSubmit={handleSubmit}>
            <Card>
              <CardContent>
                <TextField
                  label="Email"
                  name="email"
                  value={email}
                  onChange={handleChange}
                  helperText={emailError}
                />
                <TextField
                  label="Password"
                  name="password"
                  type="password"
                  value={password}
                  onChange={handleChange}
                  helperText={passwordError}
                />
                <Link to="/forgot-password">
                  Forgot Password?
                </Link>
              </CardContent>
              <CardActions>
                <LoginButton type="submit">
                  Log In
                </LoginButton>
              </CardActions>
            </Card>
          </form>
        </Grid>
      </LoginContainer>
    </>
  );
};

export default Login;
