import { createReducer } from '@reduxjs/toolkit';
import omit from 'lodash/omit';
import without from 'lodash/without';

import {
  getMerchants,
  createMerchant,
  updateMerchant,
  deleteMerchant,
  getFields,
  createField,
  deleteField,
} from './actions';
import { MerchantsState } from './interfaces';

const merchantsReducer = createReducer<MerchantsState>({}, (builder) => {
  builder
    .addCase(
      getMerchants.fulfilled,
      (state, { payload: { replace, merchants } }) => (
        replace
          ? merchants
          : {
            ...state,
            ...merchants,
          }
      ),
    )
    .addCase(
      createMerchant.fulfilled,
      (state, { payload: { merchant } }) => ({
        ...state,
        [merchant.id]: merchant,
      }),
    )
    .addCase(
      updateMerchant.fulfilled,
      (state, { payload: { merchant } }) => ({
        ...state,
        [merchant.id]: merchant,
      }),
    )
    .addCase(
      deleteMerchant.fulfilled,
      (state, { payload: { merchantId } }) => omit(state, merchantId),
    )
    .addCase(
      getFields.fulfilled,
      (state, { payload: { merchantId, fields } }) => ({
        ...state,
        [merchantId]: {
          ...state[merchantId],
          fieldIds: Object.keys(fields),
        },
      }),
    )
    .addCase(
      createField.fulfilled,
      (state, { payload: { merchantId, field } }) => ({
        ...state,
        [merchantId]: {
          ...state[merchantId],
          fieldIds: [
            ...state[merchantId].fieldIds!,
            field.id,
          ],
        },
      }),
    )
    .addCase(
      deleteField.fulfilled,
      (state, { payload: { merchantId, fieldId } }) => ({
        ...state,
        [merchantId]: {
          ...state[merchantId],
          fieldIds: without(state[merchantId].fieldIds, fieldId),
        },
      }),
    );
});

export default merchantsReducer;
