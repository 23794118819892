import Snackbar from '@material-ui/core/Snackbar';
import Typography from '@material-ui/core/Typography';
import Alert, { Color } from '@material-ui/lab/Alert';
import PropTypes from 'prop-types';
import React from 'react';

const propTypes = {
  message: PropTypes.string.isRequired,
  severity: PropTypes.string,
  visible: PropTypes.bool,
  handleClose: PropTypes.func,
};

const defaultProps = {
  severity: 'success',
  visible: false,
  handleClose: () => {},
};

export const AUTOHIDE_DURATION = 3000;

const AlertSnackbar = ({
  message, severity, visible, handleClose,
}: { message: string, severity: Color, visible: boolean, handleClose: () => void }) => (
  <Snackbar open={visible} onClose={handleClose} autoHideDuration={AUTOHIDE_DURATION}>
    <Alert severity={severity} onClose={handleClose}>
      <Typography>{message}</Typography>
    </Alert>
  </Snackbar>
);

AlertSnackbar.propTypes = propTypes;
AlertSnackbar.defaultProps = defaultProps;

export default AlertSnackbar;
