import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import LinearProgress from '@material-ui/core/LinearProgress';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { all as promiseAll } from 'bluebird';
import {
  getRepository,
  CollectionId,
  MerchantRepository,
  Region,
  RegionRepository,
} from 'linebuster-types';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import {
  usePrevious,
  useRegions,
  useRegionsByIds,
  useRegionsLoaded,
  useRegionsPending,
} from '../../../store/hooks';
import LoadMoreButton from '../../lib/LoadMoreButton';
import Page from '../../lib/Page';
import TableHeading from '../../lib/TableHeading';
import DeleteRegionModal from './DeleteRegionModal';
import EditRegionModal from './EditRegionModal';
import MerchantSearch from './MerchantSearch';

const regionRepository = getRepository<RegionRepository>(CollectionId.Regions);
const merchantRepository = getRepository<MerchantRepository>(CollectionId.Merchants);

const getRegionByName = (name: string) => regionRepository.findBy('name', name);

const getRegionByMerchantMid = async (mid: string) => {
  const merchant = await merchantRepository.findBy('cloverMid', mid);
  if (merchant == null) {
    return undefined;
  }

  const regionId = merchant.region.id;
  return regionRepository.findById(regionId);
};

const getRegionByMerchantName = async (name: string) => {
  const merchant = await merchantRepository.findBy('name', name);
  if (merchant == null) {
    return undefined;
  }

  const regionId = merchant.region.id;
  return regionRepository.findById(regionId);
};

const Regions = () => {
  const [regions, getRegions] = useRegions();
  const regionsEndOfList = useRegionsLoaded();
  const [editingRegionId, setEditingRegionId] = useState<string | null | undefined>(undefined);
  const [deletingRegionId, setDeletingRegionId] = useState<string | null>(null);
  const [, getRegionsByIds] = useRegionsByIds();
  const isPending = useRegionsPending();
  const [searchResultIds, setSearchResultIds] = useState<string[] | null>(null);
  const prevSearchResultIds = usePrevious(searchResultIds);
  const isSearch = searchResultIds !== null;

  useEffect(() => {
    getRegions(null);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (searchResultIds !== prevSearchResultIds) {
      if (isSearch) {
        getRegionsByIds(searchResultIds!);
      } else {
        getRegions(null);
      }
    }
  }, [
    searchResultIds,
    prevSearchResultIds,
    getRegions,
    getRegionsByIds,
    isSearch,
  ]);

  const handleLoadMore = () => {
    const regionsLength = regions.length;
    if (regionsLength < 1) {
      return;
    }
    const lastRegion = regions[regionsLength - 1];
    getRegions(lastRegion.id);
  };

  const handleNewRegion = () => {
    setEditingRegionId(null);
  };

  const handleSearch = async (search: string): Promise<void> => {
    const results = await promiseAll([
      getRegionByName(search),
      getRegionByMerchantMid(search),
      getRegionByMerchantName(search),
    ]);
    const ids = results.reduce((acc: string[], result: Region | undefined) => {
      if (result == null) {
        return acc;
      }
      return [
        ...acc,
        result!.id,
      ];
    }, []);
    setSearchResultIds(ids);
  };

  const renderEdit = () => {
    if (editingRegionId === undefined) {
      return null;
    }

    return (
      <EditRegionModal
        regionId={editingRegionId}
        close={() => setEditingRegionId(undefined)}
      />
    );
  };

  const renderDelete = () => {
    if (deletingRegionId === null) {
      return null;
    }

    return (
      <DeleteRegionModal
        regionId={deletingRegionId}
        close={() => setDeletingRegionId(null)}
      />
    );
  };

  const renderRegionsTable = () => (
    <>
      <TableHeading
        label="Regions"
        onAdd={isSearch ? null : () => handleNewRegion()}
        RightComponent={(
          <MerchantSearch
            isSearch={isSearch}
            handleSearch={handleSearch}
            clearSearch={() => setSearchResultIds(null)}
          />
        )}
      />
      {isPending && <LinearProgress color="secondary" />}
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="left">Name</TableCell>
              <TableCell align="center">Merchants</TableCell>
              <TableCell align="center">Edit</TableCell>
              <TableCell align="center">Delete</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.values(regions).map(({ id, name }) => (
              <TableRow key={id}>
                <TableCell align="left">{name}</TableCell>
                <TableCell align="center">
                  <Button
                    to={`/regions/${id}/merchants`}
                    component={Link}
                    variant="text"
                    color="secondary"
                  >
                    Manage
                  </Button>
                </TableCell>
                <TableCell align="center">
                  <IconButton onClick={() => setEditingRegionId(id)}>
                    <EditIcon />
                  </IconButton>
                </TableCell>
                <TableCell align="center">
                  <IconButton onClick={() => setDeletingRegionId(id)}>
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <LoadMoreButton
        hasLoaded={regionsEndOfList}
        loadMore={handleLoadMore}
      />
    </>
  );

  return (
    <Page
      title="Merchant Management"
      description="By adding a new merchant with a service fee merchant ID,  you are setting up this merchant to be able to run service fees on another account. If a particular merchant does not participate in this program, please leave the service fee MID and service fee amount blank."
    >
      {renderRegionsTable()}
      {renderEdit()}
      {renderDelete()}
    </Page>
  );
};

export default Regions;
