// @ts-nocheck
import { createAsyncThunk, createAction } from '@reduxjs/toolkit';
import firebase from 'firebase/app';
import {
  getRepository,
  CollectionId,
  Merchant,
  MerchantRepository,
  Export,
  ExportRepository,
  ExportStatus,
  FieldRepository,
  FieldVersionRepository,
  FieldVersion,
  Field,
  User,
  UserEntry,
  UserRepository,
  Region,
  RegionRepository,
} from 'linebuster-types';
import { DateTime } from 'luxon';

import { QUERY_LIMIT } from '../config/constants';
import firestore from '../services/firestore';
import {
  fetchUsers,
  updateUserFn,
  createUserFn,
  deleteUserFn,
  assignMerchantsFn,
  setPasswordFn,
  forgotPasswordFn,
  createMerchantFn,
} from '../services/functions';
import { AlertState } from './interfaces';

const merchantRepository = getRepository<MerchantRepository>(CollectionId.Merchants);
const fieldRepository = getRepository<FieldRepository>(CollectionId.Fields);
const fieldVersionRepository = getRepository<FieldVersionRepository>(CollectionId.FieldVersions);
const regionRepository = getRepository<RegionRepository>(CollectionId.Regions);
const userRepository = getRepository<UserRepository>(CollectionId.Users);
const exportRepository = getRepository<ExportRepository>(CollectionId.Exports);

export const getRegions = createAsyncThunk(
  'regions/get',
  async ({ lastId }: { lastId: string | null }) => {
    const regions = await regionRepository.findAll({
      limit: QUERY_LIMIT,
      orderBy: 'name',
      afterId: lastId || undefined,
    });
    return {
      regions: regions.reduce((result, region) => ({
        ...result,
        [region.id]: region.serialize(),
      }), {}),
    };
  },
);

export const getRegionsByIds = createAsyncThunk(
  'regions/getByIds',
  async ({ ids }: { ids: string[] }) => {
    if (ids.length < 1) {
      return { regions: {} };
    }

    const querySnapshot = await firestore
      .collection(CollectionId.Regions)
      .where(firebase.firestore.FieldPath.documentId(), 'in', ids)
      .withConverter(regionRepository)
      .get();
    return {
      regions: querySnapshot.docs.reduce((result, snapshot) => ({
        ...result,
        [snapshot.id]: snapshot.data().serialize(),
      }), {}),
    };
  },
);

export const getUsers = createAsyncThunk(
  'users/get',
  async ({ lastId }: { lastId: string | null }) => {
    const users = await fetchUsers({ afterId: lastId || undefined });
    return { users: users.data };
  },
);

export const createUser = createAsyncThunk(
  'users/create',
  async ({ user, ids }: { user: UserEntry, ids: string[], }) => {
    const createdUser = await createUserFn({
      name: user.name,
      email: user.email,
      role: user.role,
      ids,
    });
    return { user: createdUser.data };
  },
);

export const updateUser = createAsyncThunk(
  'users/update',
  async ({ user }: { user: UserEntry }) => {
    const updatedUser = await updateUserFn({
      name: user.name,
      email: user.email,
      userId: user.id,
    });
    return { user: updatedUser.data };
  },
);

export const assignMerchants = createAsyncThunk(
  'manager/assignMerchants',
  async ({ ids, manager }: { ids: string[], manager: any }) => {
    const updatedManager = await assignMerchantsFn({ managerId: manager.id, ids });
    return { user: updatedManager.data };
  },
);

export const setPassword = createAsyncThunk(
  'user/setPassword',
  ({ token, password }: { token?: string, password?: string }) => (
    setPasswordFn({ token, password })
  ),
);

export const forgotPassword = createAsyncThunk(
  'user/forgotPassword',
  async ({ email }: { email?: string }) => {
    const resp = await forgotPasswordFn({ email });
    return resp;
  },
);

export const deleteUser = createAsyncThunk(
  'users/delete',
  async ({ userId }: { userId: string }) => {
    await deleteUserFn({ userId });
    return { userId };
  },
);

export const createRegion = createAsyncThunk(
  'regions/create',
  async ({ region }: { region: Region }) => {
    const id = await regionRepository.insert(region);

    const createdRegion = await regionRepository.findById(id);
    return { region: createdRegion.serialize() };
  },
);

export const updateRegion = createAsyncThunk(
  'regions/update',
  async ({ region }: { region: Region }) => {
    await regionRepository.update(region);
    const updatedRegion = await regionRepository.findById(region.id);
    return { region: updatedRegion.serialize() };
  },
);

export const deleteRegion = createAsyncThunk(
  'regions/delete',
  async ({ regionId }: { regionId: string }) => {
    const merchantsInRegion = await merchantRepository.findForRegion(regionId);
    if (merchantsInRegion.length > 0) {
      throw new Error('Merchants exist in this region.');
    }
    const region = await regionRepository.findById(regionId);
    await regionRepository.remove(region);
    return { regionId };
  },
);

export const getMerchants = createAsyncThunk(
  'merchants/get',
  async (user: User) => {
    const merchants = await userRepository.findManagedMerchants(user);
    return {
      replace: true,
      merchants: merchants.reduce((result, merchant) => ({
        ...result,
        [merchant.id]: merchant.serialize(),
      }), {}),
    };
  },
);

export const getMerchantsForRegion = createAsyncThunk(
  'merchants/get',
  async ({ regionId, lastId }: { regionId: string, lastId: string | null }) => {
    const merchants = await merchantRepository.findForRegion(regionId, {
      limit: QUERY_LIMIT,
      orderBy: 'name',
      afterId: lastId || undefined,
    });
    return {
      replace: lastId === null,
      merchants: merchants.reduce((result, merchant) => ({
        ...result,
        [merchant.id]: merchant.serialize(),
      }), {}),
    };
  },
);

export const createMerchant = createAsyncThunk(
  'merchants/create',
  async ({ merchant, regionId }: { merchant: Merchant, regionId: string, }) => {
    const serializedMerchant = await createMerchantFn({ merchant, regionId });

    return { merchant: serializedMerchant.data };
  },
);

export const updateMerchant = createAsyncThunk(
  'merchants/update',
  async ({ merchant }: { merchant: Merchant }) => {
    await merchantRepository.update(merchant);
    const updatedMerchant = await merchantRepository.findById(merchant.id);
    return { merchant: updatedMerchant.serialize() };
  },
);

export const deleteMerchant = createAsyncThunk(
  'merchants/delete',
  async ({ merchantId }: { merchantId: string }) => {
    await merchantRepository.removeManagerReference(merchantId);
    await merchantRepository.removeUserReference(merchantId);
    await merchantRepository.removeFields(merchantId);
    await firestore.collection(CollectionId.Merchants)
      .doc(merchantId)
      .delete();

    return { merchantId };
  },
);

export const getFields = createAsyncThunk(
  'fields/get',
  async ({ merchantId }: { merchantId: string }) => {
    const fields = await merchantRepository.findFields(merchantId);
    return {
      merchantId,
      fields: fields.reduce((result, field) => ({
        ...result,
        [field.id]: field.serialize(),
      }), {}),
    };
  },
);

export const createField = createAsyncThunk(
  'fields/create',
  async ({ merchantId, field, fieldVersion }: {
    merchantId: string,
    field: Field,
    fieldVersion: FieldVersion,
  }) => {
    await fieldRepository.validate(field);
    await fieldVersionRepository.validate(fieldVersion);

    const merchantFieldsRef = firestore.collection(CollectionId.Merchants)
      .doc(merchantId)
      .collection(CollectionId.Fields);
    const fieldRef = await merchantFieldsRef
      .withConverter(fieldRepository)
      .add(field);
    await fieldRef.collection(CollectionId.FieldVersions)
      .withConverter(fieldVersionRepository)
      .add(fieldVersion);

    const newFieldSnapshot = await fieldRef.get();
    const newField = newFieldSnapshot.data()!;
    newField.currentVersion = await fieldRepository.loadCurrentFieldVersion(
      merchantId,
      newField.id,
    );

    return {
      merchantId,
      field: newField.serialize(),
    };
  },
);

export const createFieldVersion = createAsyncThunk(
  'fields/update',
  async ({ merchantId, fieldId, fieldVersion }: {
    merchantId: string,
    fieldId: string,
    fieldVersion: FieldVersion,
  }) => {
    await fieldVersionRepository.validate(fieldVersion);

    const fieldVersionRef = await firestore.collection(CollectionId.Merchants)
      .doc(merchantId)
      .collection(CollectionId.Fields)
      .doc(fieldId)
      .collection(CollectionId.FieldVersions)
      .withConverter(fieldVersionRepository)
      .add(fieldVersion);
    const currentVersionSnapshot = await fieldVersionRef.get();
    return {
      fieldId,
      currentVersion: currentVersionSnapshot.data()?.serialize(),
    };
  },
);

export const deleteField = createAsyncThunk(
  'fields/delete',
  async ({ merchantId, fieldId }: {
    merchantId: string,
    fieldId: string,
  }) => {
    await fieldRepository.removeFieldVersions(merchantId, fieldId);
    await firestore.collection(CollectionId.Merchants)
      .doc(merchantId)
      .collection(CollectionId.Fields)
      .doc(fieldId)
      .delete();
    return {
      merchantId,
      fieldId,
    };
  },
);

export const getMostRecentExport = createAsyncThunk(
  'exports/get',
  async ({ userId, merchantId }: { userId: string, merchantId: string | null }) => {
    const exp = await exportRepository.findMostRecent(userId, merchantId);
    return exp ? { export: exp.serialize() } : null;
  },
);

export const observeExport = createAsyncThunk(
  'exports/observe',
  async (id: string) => new Promise<{ export: { [key: string]: any } }>((resolve, reject) => {
    const unsub = firestore
      .collection(CollectionId.Exports)
      .withConverter(exportRepository)
      .doc(id)
      .onSnapshot((snapshot) => {
        const exp = snapshot.data();
        if (exp?.status !== ExportStatus.Pending) {
          resolve({ export: exp?.serialize()! });
          unsub();
        }
      }, (err) => {
        reject(err);
        unsub();
      });
  }),
);

export const createExport = createAsyncThunk(
  'exports/create',
  async ({
    userId,
    merchantId,
    startDate,
    endDate,
  }: {
    userId: string,
    merchantId: string | null,
    startDate: DateTime,
    endDate: DateTime,
  }) => {
    const exp = new Export({
      createdAt: new Date(),
      userId,
      merchantId,
      startDate: startDate.toJSDate(),
      endDate: endDate.toJSDate(),
      status: ExportStatus.Pending,
    });
    const id = await exportRepository.insert(exp);

    const createdExport = await exportRepository.findById(id);
    return { export: createdExport.serialize() };
  },
);

export const setAlert = createAction<AlertState>('alert/update');
