import CircularProgress from '@material-ui/core/CircularProgress';
import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  text-align: center;
`;

const Spinner = () => (
  <Container>
    <CircularProgress color="secondary" />
  </Container>
);

export default Spinner;
