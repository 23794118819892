import { createReducer } from '@reduxjs/toolkit';
import omit from 'lodash/omit';

import {
  getUsers,
  createUser,
  deleteUser,
  updateUser,
  assignMerchants,
} from './actions';
import { UsersState } from './interfaces';

const usersReducer = createReducer<UsersState>({}, (builder) => {
  builder
    .addCase(
      getUsers.fulfilled,
      (state, { payload: { users } }) => ({
        ...state,
        ...users,
      }),
    )
    .addCase(
      createUser.fulfilled,
      (state, { payload: { user } }) => ({
        [user.id]: user,
        ...state,
      }),
    )
    .addCase(
      deleteUser.fulfilled,
      (state, { payload: { userId } }) => omit(state, userId),
    )
    .addCase(
      assignMerchants.fulfilled,
      (state, { payload: { user } }) => ({
        ...state,
        [user.id]: { ...user },
      }),
    )
    .addCase(
      updateUser.fulfilled,
      (state, { payload: { user } }) => ({
        ...state,
        [user.id]: { ...user },
      }),
    );
});

export default usersReducer;
