import firebase from 'firebase/app';

const fn = firebase.functions();

if (process.env.REACT_APP_USE_EMULATOR === 'true') {
  fn.useEmulator('localhost', 5001);
}

export const exportCsv = fn.httpsCallable('exportCsv');
export const fetchUsers = fn.httpsCallable('fetchUsers');
export const updateUserFn = fn.httpsCallable('updateUser');
export const createUserFn = fn.httpsCallable('createUser');
export const deleteUserFn = fn.httpsCallable('deleteUser');
export const assignMerchantsFn = fn.httpsCallable('assignMerchants');
export const setPasswordFn = fn.httpsCallable('setPassword');
export const forgotPasswordFn = fn.httpsCallable('forgotPassword');
export const createMerchantFn = fn.httpsCallable('createMerchant');
export const logFn = fn.httpsCallable('log');
