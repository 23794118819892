import LinearProgress from '@material-ui/core/LinearProgress';
import ArrowBackIos from '@material-ui/icons/ArrowBackIos';
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import {
  useRegionIdParam,
  useRegions,
  useRegionsPending,
} from '../../../store/hooks';
import MerchantsPage from './MerchantsPage';

const BackLink = styled(Link)`
  text-decoration: none;
  color: #000;
  display: flex;
  line-height: 18px;
  text-transform: uppercase;
  font-weight: 700;
  margin-bottom: ${({ theme }) => theme.spacing(2)}px;
`;

const StyledArrowBackIos = styled(ArrowBackIos)`
  color: ${({ theme }) => theme.palette.secondary.main};
  font-size: 16px;
`;

const Merchants = () => {
  const regionId = useRegionIdParam();
  const [regions, getRegions] = useRegions();
  const isPending = useRegionsPending();

  useEffect(() => {
    getRegions(null);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <BackLink to="/regions">
        <StyledArrowBackIos />
        All Regions
      </BackLink>
      {regions.length === 0 || isPending ? (
        <LinearProgress />
      ) : (
        <MerchantsPage regionId={regionId!} />
      )}
    </>
  );
};

export default Merchants;
