import AppBar from '@material-ui/core/AppBar';
import Container from '@material-ui/core/Container';
import LinearProgress from '@material-ui/core/LinearProgress';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import firebase from 'firebase/app';
import React, { useContext, useEffect, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import styled from 'styled-components';

import { UserContext } from './components/contexts';
import AlertSnackbar from './components/lib/AlertSnackbar';
import Navigation from './components/router/Navigation';
import Routes from './components/router/Routes';
import auth from './services/auth';
import { useUserRepository, useSetAlert } from './store/hooks';

const NavBar = styled(AppBar)`
  margin-bottom: ${({ theme }) => theme.spacing(6)}px;
`;

const NavToolbar = styled(Toolbar)`
  justify-content: space-between;
`;

const App = () => {
  const { user, setUser } = useContext(UserContext);
  const userRepository = useUserRepository();
  const [customFieldsTabDisabled, setCustomFieldsTabDisabled] = useState(false);

  const [alert, setAlert] = useSetAlert();
  const handleSetUser = async (firebaseUser: firebase.User | null) => {
    if (firebaseUser === null) {
      setUser(null);
      return;
    }

    const firestoreUser = await userRepository.findById(firebaseUser.uid);
    const userMerchants = await userRepository.findManagedMerchants(firestoreUser);

    const firstMerchant = userMerchants[0];
    // We want to hide the entire Custom Fields tab for managers that
    // have the first merchant customFieldsRequired flag in OFF state
    // TODO 03/24/22: This is not ideal and could be improved so needs to be revisit
    // in the future. With latest client feedback, having the flag for each
    // merchant is not required, we just need one flag for the entire region.
    if (firestoreUser.role === 'manager'
          && (!firstMerchant || firstMerchant.customFieldsRequired === false)) {
      setCustomFieldsTabDisabled(true);
    }
    setUser(firestoreUser);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => auth.onAuthStateChanged(handleSetUser), []);

  return (
    <BrowserRouter>
      <NavBar position="static" elevation={0}>
        <NavToolbar>
          <Typography variant="h1">fiserv QuickPass</Typography>
          {user !== null && <Navigation customFieldsTabDisabled={customFieldsTabDisabled} />}
        </NavToolbar>
        {user === undefined && <LinearProgress color="secondary" />}
      </NavBar>
      <Container maxWidth="xl">
        {user !== undefined && <Routes customFieldsTabDisabled={customFieldsTabDisabled} />}
      </Container>
      <AlertSnackbar
        message={alert.message}
        severity={alert.severity}
        visible={alert.visible}
        handleClose={() => setAlert({ visible: false })}
      />
    </BrowserRouter>
  );
};

export default App;
