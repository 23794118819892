import IconButton from '@material-ui/core/IconButton';
import LinearProgress from '@material-ui/core/LinearProgress';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { Role, UserEntry } from 'linebuster-types';
import filter from 'lodash/filter';
import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';

import {
  useUsers,
  useDeleteUser,
  useUsersLoaded,
  useUsersPending,
} from '../../../store/hooks';
import LoadMoreButton from '../../lib/LoadMoreButton';
import Page from '../../lib/Page';
import TableHeading from '../../lib/TableHeading';
import UserManagementForm from './UserManagementForm';

const UserManagement = () => {
  const [users, getUsers] = useUsers();
  const [editingUser, setEditingUser] = useState<UserEntry>();
  const deleteUser = useDeleteUser();
  const isPending = useUsersPending();
  const usersEndOfList = useUsersLoaded();

  useEffect(() => {
    getUsers(null);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleLoadMore = () => {
    const usersLength = users.length;
    if (usersLength < 1) {
      return;
    }
    const lastUser = users[usersLength - 1];
    getUsers(lastUser.id);
  };

  const handleEdit = (user: any) => {
    setEditingUser(user);
  };

  const handleDelete = (user: any) => {
    deleteUser(user.id);
  };

  const handleNewUser = () => {
    setEditingUser(new UserEntry({}));
  };

  const handleDoneEditingUser = () => {
    setEditingUser(undefined);
  };

  const renderDate = (dateString?: string) => {
    if (!dateString) {
      return '';
    }

    const date = DateTime.fromJSDate(new Date(dateString));
    return date.toLocaleString(DateTime.DATE_SHORT);
  };

  const filteredUsers = filter(users, (user) => user.role !== Role.Merchant);

  const renderUserTable = () => (
    <>
      <TableHeading
        label="Users"
        onAdd={() => handleNewUser()}
      />
      {isPending && <LinearProgress color="secondary" />}
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="left">Name</TableCell>
              <TableCell align="right">Email</TableCell>
              <TableCell align="right">Role</TableCell>
              <TableCell align="right">Date Added</TableCell>
              <TableCell align="center">Edit</TableCell>
              <TableCell align="center">Delete</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.values(filteredUsers).map((row) => (
              <TableRow key={row.id}>
                <TableCell align="left">{row.name}</TableCell>
                <TableCell align="right">{row.email}</TableCell>
                <TableCell align="right">{row.role}</TableCell>
                <TableCell align="right">{renderDate(row.dateAdded)}</TableCell>
                <TableCell align="center">
                  <IconButton onClick={() => handleEdit(row)}>
                    <EditIcon />
                  </IconButton>
                </TableCell>
                <TableCell align="center">
                  <IconButton onClick={() => handleDelete(row)}>
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <LoadMoreButton
        hasLoaded={usersEndOfList}
        loadMore={handleLoadMore}
      />
    </>
  );

  return (
    <Page
      title="User Management"
      description="Invite users to the app, or remove them as needed."
    >
      {editingUser ? (
        <UserManagementForm
          handleDoneEditingUser={handleDoneEditingUser}
          user={editingUser}
        />
      ) : renderUserTable()}
    </Page>
  );
};

export default UserManagement;
