import PropTypes, { InferProps } from 'prop-types';
import React, { useContext } from 'react';
import { Redirect, Route } from 'react-router-dom';

import { RoutePath, landingPages } from '../../config/routes';
import { UserContext } from '../contexts';

const propTypes = {
  children: PropTypes.node.isRequired,
  exact: PropTypes.bool,
  path: PropTypes.string.isRequired,
};

const AuthRoute = ({
  children,
  exact,
  path,
}: InferProps<typeof propTypes>) => {
  const { user, hasPermission } = useContext(UserContext);

  const render = () => {
    if (user == null) {
      return <Redirect to={RoutePath.LOGIN} />;
    }
    if (!hasPermission(path)) {
      return <Redirect to={landingPages[user.role]} />;
    }
    return children;
  };

  return (
    <Route
      path={path}
      exact={exact || false}
      render={render}
    />
  );
};

AuthRoute.propTypes = propTypes;
AuthRoute.defaultProps = {
  exact: false,
};

export default AuthRoute;
