import PropTypes, { InferProps } from 'prop-types';
import React, { useContext } from 'react';
import { NavLink } from 'react-router-dom';

import { UserContext } from '../contexts';
import TopBarButton from '../lib/TopBarButton';

const propTypes = {
  children: PropTypes.node.isRequired,
  path: PropTypes.string.isRequired,
};

const NavButton = ({
  children,
  path,
}: InferProps<typeof propTypes>) => {
  const { hasPermission } = useContext(UserContext);

  if (!hasPermission(path)) {
    return null;
  }

  return (
    <TopBarButton
      component={NavLink}
      to={path}
    >
      {children}
    </TopBarButton>
  );
};

NavButton.propTypes = propTypes;

export default NavButton;
