import { createReducer } from '@reduxjs/toolkit';
import omit from 'lodash/omit';

import {
  getRegions,
  getRegionsByIds,
  createRegion,
  updateRegion,
  deleteRegion,
} from './actions';
import { RegionsState } from './interfaces';

const regionsReducer = createReducer<RegionsState>({}, (builder) => {
  builder
    .addCase(
      getRegions.fulfilled,
      (state, { payload: { regions } }) => ({
        ...state,
        ...regions,
      }),
    )
    .addCase(
      getRegionsByIds.fulfilled,
      (state, { payload: { regions } }) => regions,
    )
    .addCase(
      createRegion.fulfilled,
      (state, { payload: { region } }) => ({
        ...state,
        [region.id]: region,
      }),
    )
    .addCase(
      deleteRegion.fulfilled,
      (state, { payload: { regionId } }) => omit(state, regionId),
    )
    .addCase(
      updateRegion.fulfilled,
      (state, { payload: { region } }) => ({
        ...state,
        [region.id]: region,
      }),
    );
});

export default regionsReducer;
