import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import PropTypes, { InferProps } from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  text-align: center;
  margin-top: ${({ theme }) => theme.spacing(1)}px;
`;

const FinishedText = styled(Typography)`
  color: ${({ theme }) => theme.palette.grey[400]};
`;

const propTypes = {
  hasLoaded: PropTypes.bool.isRequired,
  loadMore: PropTypes.func.isRequired,
};

const LoadMoreButton = ({ hasLoaded, loadMore }: InferProps<typeof propTypes>) => (
  <Container>
    {hasLoaded ? (
      <FinishedText variant="button">Loaded</FinishedText>
    ) : (
      <Button variant="text" onClick={loadMore}>Load More</Button>
    )}
  </Container>
);

LoadMoreButton.propTypes = propTypes;

export default LoadMoreButton;
