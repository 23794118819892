import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';
import Tooltip from '@material-ui/core/Tooltip';
import { each as promiseForEach } from 'bluebird';
import { Merchant, FeeType } from 'linebuster-types';
import toNumber from 'lodash/toNumber';
import PropTypes, { InferProps } from 'prop-types';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import {
  useRegion,
  useMerchantsForRegion,
  useMerchantsLoaded,
  useUpdateMerchant,
  useMerchantsPending,
} from '../../../store/hooks';
import LoadMoreButton from '../../lib/LoadMoreButton';
import Page from '../../lib/Page';
import TableHeading from '../../lib/TableHeading';
import BulkModal from './BulkModal';
import MerchantForm from './MerchantForm';

const MerchantTable = styled.table`
  width: 100%;
  & td {
    padding: 0 ${({ theme }) => theme.spacing(1)}px;
  }
`;

const ProgressBar = styled(LinearProgress)`
  margin: 0 -${({ theme }) => theme.spacing(1)}px;
`;

const ServiceFeeAllButton = styled(Button)`
  color: ${({ theme }) => theme.palette.secondary.main};
  border: 1px solid ${({ theme }) => theme.palette.secondary.main};
  &:hover {
    color: ${({ theme }) => theme.palette.secondary.main};
    background-color: ${({ theme }) => theme.palette.secondary.lighter};
    border: 1px solid ${({ theme }) => theme.palette.secondary.main};
  }
`;

const SwitchAllButton = styled(ServiceFeeAllButton)`
`;

const TableHeader = styled.th`
  background-color: ${({ theme }) => theme.palette.grey[200]};
  padding: ${({ theme }) => theme.spacing(2)}px;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
`;

const propTypes = {
  regionId: PropTypes.string.isRequired,
};

const MerchantsPage = ({ regionId }: InferProps<typeof propTypes>) => {
  const region = useRegion(regionId);
  const merchantsEndOfList = useMerchantsLoaded();
  const [merchants, getMerchantsForRegion] = useMerchantsForRegion();
  const isPending = useMerchantsPending();
  const [hasNew, setHasNew] = useState<boolean>(false);
  const [bulkServiceFeeModalVisible, setBulkServiceFeeModalVisible] = useState<boolean>(false);
  const [bulkCustomFieldsModalVisible, setBulkCustomFieldsModalVisible] = useState<boolean>(false);
  const updateMerchant = useUpdateMerchant();

  const loadMerchants = () => getMerchantsForRegion(regionId, null);

  useEffect(() => {
    loadMerchants();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleLoadMore = () => {
    const merchantsLength = merchants.length;
    if (merchantsLength < 1) {
      return;
    }
    const lastMerchant = merchants[merchantsLength - 1];
    getMerchantsForRegion(regionId, lastMerchant.id);
  };

  const firstMerchant = new Merchant(merchants[0]);

  const applyServiceFeeSettingsToAll = async () => {
    await promiseForEach(merchants.slice(1), async (merchant) => {
      const currentMerchant = new Merchant(merchant);
      currentMerchant.serviceFeeCloverMid = firstMerchant.serviceFeeCloverMid;
      currentMerchant.serviceFeeValue = firstMerchant.serviceFeeValue;
      currentMerchant.serviceFeeType = firstMerchant.serviceFeeType;
      await updateMerchant(currentMerchant);
    });
    await loadMerchants();
  };

  const applyCustomFieldsSettingsToAll = async () => {
    await promiseForEach(merchants.slice(1), async (merchant) => {
      const currentMerchant = new Merchant(merchant);
      currentMerchant.customFieldsRequired = firstMerchant.customFieldsRequired;
      await updateMerchant(currentMerchant);
    });
    await loadMerchants();
  };

  const firstMerchantValid = firstMerchant && firstMerchant.serviceFeeCloverMid
    && toNumber(firstMerchant.serviceFeeValue) >= 0
    && firstMerchant.serviceFeeType;

  const serviceFeeTooltip = firstMerchantValid
    ? 'Click here to copy service fee settings of the first entry across all merchants.'
    : 'Please make sure the first merchant on this page has valid service fee settings before using this feature.';

  const customFieldsToolTip = firstMerchantValid
    ? 'Click here to copy custom fields setting of the first entry across all merchants.'
    : 'Please make sure the first merchant on this page has valid custom fields setting before using this feature.';

  return (
    <Page title={region!.name}>
      <TableHeading
        label="Merchants"
        onAdd={hasNew ? null : () => setHasNew(true)}
        onRemove={hasNew ? () => setHasNew(false) : null}
      />
      {isPending && <ProgressBar color="secondary" />}
      <MerchantTable>
        <thead>
          <tr>
            <th>{}</th>
            <th>{}</th>
            <TableHeader>{}</TableHeader>
            <TableHeader>{}</TableHeader>
            <TableHeader>
              <Tooltip title={serviceFeeTooltip}>
                <ButtonContainer>
                  {/* Necessary ButtonContainer wrapper for tooltip to work on disabled buttons */}
                  <ServiceFeeAllButton
                    disabled={hasNew || !firstMerchantValid}
                    onClick={() => setBulkServiceFeeModalVisible(true)}
                    variant="outlined"
                  >
                    Apply to all
                  </ServiceFeeAllButton>
                </ButtonContainer>
              </Tooltip>
            </TableHeader>
            <th>{}</th>
            <TableHeader>
              <Tooltip title={customFieldsToolTip}>
                <ButtonContainer>
                  <SwitchAllButton
                    disabled={hasNew || !firstMerchantValid}
                    onClick={() => setBulkCustomFieldsModalVisible(true)}
                    variant="outlined"
                  >
                    Apply to all
                  </SwitchAllButton>
                </ButtonContainer>
              </Tooltip>

            </TableHeader>
            <th>{}</th>
          </tr>
        </thead>
        <tbody>
          {hasNew && (
            <MerchantForm
              key="new"
              merchant={new Merchant({
                name: '',
                cloverMid: '',
                serviceFeeCloverMid: '',
                serviceFeeValue: 0,
                serviceFeeType: FeeType.None,
                customFieldsRequired: true,
              })}
              closeNew={() => setHasNew(false)}
              regionId={regionId}
            />
          )}
          {merchants.map((merchant) => (
            <MerchantForm
              firstMerchantName={firstMerchant.name}
              key={merchant.id}
              merchant={new Merchant(merchant)}
              regionId={regionId}
            />
          ))}
        </tbody>
      </MerchantTable>
      <LoadMoreButton
        hasLoaded={merchantsEndOfList}
        loadMore={handleLoadMore}
      />
      <BulkModal
        settingName="service fee"
        visible={bulkServiceFeeModalVisible}
        onClose={() => setBulkServiceFeeModalVisible(false)}
        onConfirm={applyServiceFeeSettingsToAll}
      />
      <BulkModal
        settingName="custom fields"
        visible={bulkCustomFieldsModalVisible}
        onClose={() => setBulkCustomFieldsModalVisible(false)}
        onConfirm={applyCustomFieldsSettingsToAll}
      />
    </Page>
  );
};

MerchantsPage.propTypes = propTypes;

export default MerchantsPage;
