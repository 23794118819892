import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormLabel from '@material-ui/core/FormLabel';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import TextField from '@material-ui/core/TextField';
import DeleteIcon from '@material-ui/icons/Delete';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useFormik } from 'formik';
import {
  Merchant,
  Role,
  UserEntry,
} from 'linebuster-types';
import compact from 'lodash/compact';
import isEmpty from 'lodash/isEmpty';
import uniq from 'lodash/uniq';
import without from 'lodash/without';
import xor from 'lodash/xor';
import PropTypes, { InferProps } from 'prop-types';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import {
  useCreateUser,
  useUpdateUser,
  useMerchants,
  useAssignMerchants,
} from '../../../store/hooks';

const CancelButton = styled(Button)`
  margin-right: ${({ theme }) => theme.spacing(1)}px;
`;

const DeleteIconStyled = styled(Grid)`
  padding-left: 0;
  padding-bottom: 0;
  margin-top: ${({ theme }) => theme.spacing(2)}px;
  position: relative;
  left: ${({ theme }) => theme.spacing(-3)}px;
`;

interface FormState {
  name: string,
  email: string,
  role: Role,
  merchants: Merchant[],
}

const propTypes = {
  user: PropTypes.any.isRequired,
  handleDoneEditingUser: PropTypes.func.isRequired,
};

const UserManagementForm = ({
  user,
  handleDoneEditingUser,
}: InferProps<typeof propTypes>) => {
  const updateUser = useUpdateUser();
  const createUser = useCreateUser();
  const assignMerchants = useAssignMerchants();
  const [allMerchants, getAllMerchants] = useMerchants();
  const [assignedMerchants, setAssignedMerchants] = useState<string[]>([]);

  useEffect(() => {
    getAllMerchants();
    setAssignedMerchants(user.merchants || []);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const validate = ({
    name,
    email,
    role,
  }: FormState) => {
    const errors: {
      name?: string,
      email?: string,
      role?: string,
    } = {};

    if (name === '') {
      errors.name = 'Name is required';
    }
    if (email === '') {
      errors.email = 'Email is required';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
      errors.email = 'Invalid email address';
    }
    if (!role) {
      errors.role = 'Role is required';
    }
    return errors;
  };

  const {
    values: {
      name,
      email,
      role,
      merchants,
    },
    errors: {
      name: nameError,
      email: emailError,
      role: roleError,
    },
    handleChange,
    handleBlur,
    handleSubmit,
    isValid,
    setFieldTouched,
    touched,
  } = useFormik({
    initialValues: {
      name: user.name,
      email: user.email,
      role: user.role,
      merchants: user.merchants,
    },
    validate,
    onSubmit: () => {
      const userData = new UserEntry({
        name,
        email,
        role,
        merchants,
      });

      userData.id = user.id;
      if (userData.id) {
        updateUser(userData);
        if (assignedMerchants.length > 0) {
          assignMerchants(assignedMerchants, userData);
        }
      } else {
        createUser(userData, assignedMerchants);
      }

      handleDoneEditingUser();
    },
  });

  const onMerchantSelected = (e: any, value: any) => {
    if (!value) {
      return;
    }
    setAssignedMerchants(uniq([...assignedMerchants, value.id]));
  };

  const handleRemoveAssociation = (mid: string) => {
    setAssignedMerchants(without(assignedMerchants, mid));
  };

  const merchantsAssociationChanged = () => !isEmpty(xor(assignedMerchants, user.merchants));

  const merchantsFromIds = () => compact(
    assignedMerchants.map((mid: string) => allMerchants.find((merch) => merch.id === mid)),
  );

  return (
    <form onSubmit={handleSubmit}>
      <Grid container>
        <Grid item xs={4}>
          <TextField
            name="name"
            label="Name"
            value={name}
            helperText={nameError}
            onChange={(e) => {
              setFieldTouched('name', true);
              handleChange(e);
            }}
            onBlur={handleBlur}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            name="email"
            label="Email"
            value={email}
            helperText={emailError}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </Grid>
        {user.id ? (
          <Grid item xs={4} />
        ) : (
          <Grid item xs={4}>
            <FormControl component="fieldset" fullWidth>
              <FormLabel component="legend" />
              <RadioGroup
                name="role"
                value={role}
                onChange={handleChange}
                onBlur={handleBlur}
                row
              >
                <FormControlLabel
                  value="admin"
                  label="Admin"
                  control={<Radio />}
                />
                <FormControlLabel
                  value="manager"
                  label="Manager"
                  control={<Radio />}
                />
              </RadioGroup>
              <FormHelperText>{roleError}</FormHelperText>
            </FormControl>
          </Grid>
        )}
        {role === 'manager' && (
          <>
            <Grid item xs={12}>Add merchants to manage</Grid>
            <Grid item xs={12}>
              {merchantsFromIds().map((merchant: any) => (
                <Grid key={merchant.id} container xs={12}>
                  <Grid item xs={10}>
                    <TextField
                      name="merchantName"
                      label="Name or ID"
                      value={merchant.name}
                      onChange={handleChange}
                    />
                  </Grid>
                  <DeleteIconStyled item xs={2}>
                    <IconButton onClick={() => handleRemoveAssociation(merchant.id)}>
                      <DeleteIcon />
                    </IconButton>
                  </DeleteIconStyled>
                </Grid>
              ))}
            </Grid>
            <Grid item xs={4}>
              <Autocomplete
                options={allMerchants}
                clearOnBlur
                getOptionLabel={(option) => `${option.name} (${option.id})`}
                onChange={onMerchantSelected}
              // eslint-disable-next-line react/jsx-props-no-spreading
                renderInput={(params) => <TextField {...params} label="Name or ID" variant="outlined" />}
              />
            </Grid>
            <Grid item xs={8} />
          </>
        )}
        <Grid item xs={4}>
          <CancelButton
            onClick={handleDoneEditingUser}
            color="default"
          >
            Cancel
          </CancelButton>
          <Button
            type="submit"
            disabled={!isValid || (isEmpty(touched) && !merchantsAssociationChanged())}
          >
            { user.id ? 'Update' : 'Create' }
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

UserManagementForm.propTypes = propTypes;

export default UserManagementForm;
