import IconButton from '@material-ui/core/IconButton';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import PropTypes, { InferProps } from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const GreenButton = styled(IconButton)`
  color: ${({ theme }) => theme.palette.success.main};
`;

const propTypes = {
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

const CheckButton = ({ onClick, disabled }: InferProps<typeof propTypes>) => (
  <GreenButton onClick={onClick} disabled={disabled!}>
    <CheckCircleIcon />
  </GreenButton>
);

CheckButton.propTypes = propTypes;
CheckButton.defaultProps = {
  disabled: false,
};

export default CheckButton;
