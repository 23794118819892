import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import TextField from '@material-ui/core/TextField';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { forgotPassword as forgotPasswordAction } from '../../../store/actions';
import { useForgotPassword } from '../../../store/hooks';

const ForgotPasswordContainer = styled(Grid)`
  justify-content: center;
`;

const ForgotPasswordButton = styled(Button).attrs(() => ({
  fullWidth: true,
}))``;

interface FormState {
  email: string,
}

const ForgotPassword = () => {
  const forgotPassword = useForgotPassword();
  const history = useHistory();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const validate = ({ email }: FormState) => {
    const errors: { email?: string, } = {};
    if (email === '') {
      errors.email = 'Email required';
    }
    return errors;
  };

  const submit = async ({ email }: FormState) => {
    setIsSubmitting(true);
    const resultAction = await forgotPassword(email);
    if (forgotPasswordAction.fulfilled.match(resultAction)) {
      history.push('/login');
    }
    setIsSubmitting(false);
  };

  const {
    values: { email },
    errors: { email: emailError },
    handleChange,
    handleSubmit,
  } = useFormik<FormState>({
    initialValues: { email: '' },
    validate,
    onSubmit: submit,
  });

  return (
    <>
      {isSubmitting && <LinearProgress color="secondary" /> }
      <ForgotPasswordContainer container>
        <Grid item xs={4}>
          <form onSubmit={handleSubmit}>
            <Card>
              <CardContent>
                <TextField
                  label="Email"
                  name="email"
                  value={email}
                  onChange={handleChange}
                  helperText={emailError}
                />
              </CardContent>
              <CardActions>
                <ForgotPasswordButton type="submit">
                  Submit
                </ForgotPasswordButton>
              </CardActions>
            </Card>
          </form>
        </Grid>
      </ForgotPasswordContainer>
    </>

  );
};

export default ForgotPassword;
