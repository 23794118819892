import Button from '@material-ui/core/Button';
import React from 'react';
import styled from 'styled-components';

const TopBarButton = styled(({ component, ...rest }) => (
  <Button
    component={component}
    variant="text"
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...rest}
  />
))`
  border-radius: 0;
  color: ${({ theme }) => theme.palette.primary.contrastText};
  margin-left: ${({ theme }) => theme.spacing(3)}px;
  padding: 0.1rem 0.5rem;
  font-size: 1.125rem;
  font-weight: normal;
  text-transform: none;
`;

export default TopBarButton;
