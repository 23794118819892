import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import PropTypes, { InferProps } from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const ColoredListItem = styled((props) => (
  <ListItem
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
    component="div"
  />
))`
  & .MuiListItemText-primary {
    font-weight: bold;
    font-size: 1.25rem;
  }
  &, & .MuiListItemIcon-root {
    color: ${({ color }) => color};
  }
`;

const propTypes = {
  text: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  IconClass: PropTypes.elementType.isRequired,
  onClick: PropTypes.func,
};

const IconItem = ({
  text,
  color,
  IconClass,
  onClick,
}: InferProps<typeof propTypes>) => (
  <ColoredListItem
    color={color}
    button={onClick !== null}
    onClick={onClick}
  >
    <ListItemIcon>
      <IconClass />
    </ListItemIcon>
    <ListItemText primary={text} />
  </ColoredListItem>
);

IconItem.propTypes = propTypes;
IconItem.defaultProps = {
  onClick: null,
};

export default IconItem;
