import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { RoutePath } from '../../config/routes';
import auth from '../../services/auth';
import logger from '../../services/logger';
import { useSetError } from '../../store/hooks';
import TopBarButton from '../lib/TopBarButton';
import NavButton from './NavButton';

const NavigationContainer = styled.div`
  .active {
    border-bottom: 3px solid ${({ theme }) => theme.palette.primary.contrastText};
  }
`;

const propTypes = {
  customFieldsTabDisabled: PropTypes.bool.isRequired,
};
interface NavigationState {
  customFieldsTabDisabled: boolean,
}

const Navigation = ({ customFieldsTabDisabled }: NavigationState) => {
  const setError = useSetError();

  const handleLogout = async () => {
    try {
      await auth.signOut();
    } catch (error) {
      logger.error(error);
      setError('There was an error logging out');
    }
  };

  return (
    <NavigationContainer>
      {
        !customFieldsTabDisabled && (
        <NavButton path={RoutePath.CUSTOM_FIELDS}>
          Custom Fields
        </NavButton>
        )
      }
      <NavButton path={RoutePath.EXPORT}>
        Export
      </NavButton>
      <NavButton path={RoutePath.REGIONS}>
        Merchants
      </NavButton>
      <NavButton path={RoutePath.USER_MANAGEMENT}>
        User Management
      </NavButton>
      <TopBarButton onClick={handleLogout}>
        Log out
      </TopBarButton>
    </NavigationContainer>
  );
};

Navigation.propTypes = propTypes;

export default Navigation;
