import { createReducer } from '@reduxjs/toolkit';

import logger from '../services/logger';
import {
  createUser, forgotPassword, setAlert, setPassword,
} from './actions';
import { AlertState } from './interfaces';

const DEFAULT_ERROR = 'There was an error updating data.';

const alertReducer = createReducer<AlertState>({ message: '', severity: 'success', visible: false }, (builder) => {
  builder
    .addCase(
      setAlert,
      (state, { payload }) => ({ ...state, ...payload }),
    )
    .addCase(
      createUser.fulfilled,
      (state) => ({
        ...state,
        message: 'Email sent for activation of account.',
        severity: 'success',
        visible: true,
      }),
    )
    .addCase(createUser.rejected,
      (state, { error }) => {
        logger.error(error);
        const message = error.code === 'already-exists' ? 'The email address is already in use by another account.' : DEFAULT_ERROR;
        return {
          ...state,
          message,
          severity: 'error',
          visible: true,
        };
      })
    .addCase(
      forgotPassword.fulfilled,
      (state) => ({
        ...state,
        message: 'Email sent for password reset.',
        severity: 'success',
        visible: true,
      }),
    )
    .addCase(forgotPassword.rejected,
      (state, { error }) => {
        logger.error(error);
        const message = error.code === 'not-found' ? 'Email not found.' : DEFAULT_ERROR;
        return {
          ...state,
          message,
          severity: 'error',
          visible: true,
        };
      })
    .addCase(
      setPassword.fulfilled,
      (state) => ({
        ...state,
        message: 'Password reset successfully.',
        severity: 'success',
        visible: true,
      }),
    )
    .addCase(setPassword.rejected,
      (state, { error }) => {
        logger.error(error);
        const message = 'Unable to reset password. Please try again.';
        return {
          ...state,
          message,
          severity: 'error',
          visible: true,
        };
      })
    .addDefaultCase((state, { type, error }) => {
      if (type.endsWith('/rejected')) {
        logger.error(error);
        return {
          ...state,
          message: DEFAULT_ERROR,
          severity: 'error',
          visible: true,
        };
      }
      return { ...state };
    });
});

export default alertReducer;
