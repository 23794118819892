import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import LinearProgress from '@material-ui/core/LinearProgress';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { DatePicker } from '@material-ui/pickers';
import { useFormik } from 'formik';
import { DateTime } from 'luxon';
import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';

import { useMerchants, useCreateExport } from '../../../store/hooks';
import { UserContext } from '../../contexts';
import Page from '../../lib/Page';
import SmallContainer from '../../lib/SmallContainer';
import ExportFile from './ExportFile';

const DateFields = styled.div`
  display: flex;
  flex-direction: row;
  margin: ${({ theme }) => theme.spacing(0, -1, 2)};
`;

const DateField = styled(DatePicker)`
  margin: ${({ theme }) => theme.spacing(0, 1)};
`;

interface FormState {
  merchantId: string,
  startDate: DateTime | null,
  endDate: DateTime | null,
}

const FIELD_NAMES = {
  merchantId: 'merchantId',
  startDate: 'startDate',
  endDate: 'endDate',
};

const ExportForm = () => {
  const { user } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [merchants, getMerchants] = useMerchants();
  const createExport = useCreateExport();

  useEffect(() => {
    getMerchants();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const validateField = (fieldName: string, value: any) => (
    {
      [FIELD_NAMES.merchantId]: () => (
        value === '' ? 'Please select a merchant' : undefined
      ),
      [FIELD_NAMES.startDate]: () => (
        value === null ? 'Please select a start date' : undefined
      ),
      [FIELD_NAMES.endDate]: () => (
        value === null ? 'Please select an end date' : undefined
      ),
    }[fieldName]()
  );

  const validate = (values: FormState) => {
    const errors: { [key: string]: string | undefined} = {};

    const merchantIdErr = validateField(FIELD_NAMES.merchantId, values.merchantId);
    const startDateErr = validateField(FIELD_NAMES.startDate, values.startDate);
    const endDateErr = validateField(FIELD_NAMES.endDate, values.endDate);

    if (merchantIdErr) {
      errors.merchantId = merchantIdErr;
    }
    if (startDateErr) {
      errors.startDate = startDateErr;
    }
    if (endDateErr) {
      errors.endDate = endDateErr;
    }
    return errors;
  };

  const {
    values,
    errors: {
      merchantId: merchantIdError,
      startDate: startDateError,
      endDate: endDateError,
    },
    handleSubmit,
    isValid,
    setFieldValue,
    setFieldError,
  } = useFormik<FormState>({
    initialValues: {
      merchantId: '',
      startDate: null,
      endDate: null,
    },
    validate,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async () => {
      const {
        merchantId,
        startDate,
        endDate,
      } = values;
      setIsLoading(true);
      createExport(
        user!.id,
        merchantId || null,
        startDate!,
        endDate!,
      );
    },
  });

  const handleChange = (fieldName: string, value: any) => {
    setFieldValue(fieldName, value);
    const error = validateField(fieldName, value);
    setFieldError(fieldName, error);
  };

  const handleMerchantChange = ({ target: { value } }: React.ChangeEvent<any>) => {
    handleChange(FIELD_NAMES.merchantId, value);
  };

  const handleDateChange = (fieldName: string) => (date: DateTime | null) => {
    handleChange(fieldName, date);
  };

  const {
    merchantId,
    startDate,
    endDate,
  } = values;

  const csvForm = () => (
    <form onSubmit={handleSubmit}>
      <FormControl fullWidth>
        <InputLabel>Merchant</InputLabel>
        <Select
          name={FIELD_NAMES.merchantId}
          value={merchantId}
          onChange={handleMerchantChange}
          disabled={isLoading}
        >
          {merchants.map(
            ({ id, name }) => <MenuItem key={id} value={id}>{name}</MenuItem>,
          )}
        </Select>
        <FormHelperText>{merchantIdError}</FormHelperText>
      </FormControl>
      <DateFields>
        <DateField
          label="Start date"
          name={FIELD_NAMES.startDate}
          value={startDate}
          format="M/d/yyyy"
          onChange={handleDateChange('startDate')}
          disableFuture
          helperText={startDateError}
          disabled={isLoading}
        />
        <DateField
          label="End date"
          name={FIELD_NAMES.endDate}
          value={endDate}
          format="M/d/yyyy"
          onChange={handleDateChange('endDate')}
          disableFuture
          helperText={endDateError}
          disabled={isLoading}
        />
      </DateFields>
      <Button
        type="submit"
        disabled={!isValid || isLoading}
        fullWidth
      >
        Generate CSV
      </Button>
      <ExportFile
        userId={user!.id}
        merchantId={merchantId || null}
        stopLoading={() => setIsLoading(false)}
      />
    </form>
  );

  return (
    <Page
      title="Export"
      description="Please choose a date range to export customer data."
    >
      <SmallContainer>
        {merchants == null ? <LinearProgress color="secondary" /> : csvForm()}
      </SmallContainer>
    </Page>
  );
};

export default ExportForm;
