import TextField from '@material-ui/core/TextField';
import { useTheme } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import LockOutlined from '@material-ui/icons/LockOutlined';
import { useFormik } from 'formik';
import { Field, FieldVersion, FieldType } from 'linebuster-types';
import PropTypes, { InferProps } from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import {
  useCreateField,
  useUpdateField,
  useDeleteField,
} from '../../../store/hooks';
import CancelButton from '../../lib/CancelButton';
import CheckButton from '../../lib/CheckButton';
import IconItem from '../../lib/IconItem';
import TableRow from '../../lib/TableRow';

interface FormState {
  prompt: string,
}

const ButtonCell = styled.td`
  width: 1%;
  white-space: nowrap;
`;

const propTypes = {
  merchantId: PropTypes.string.isRequired,
  field: PropTypes.instanceOf(Field).isRequired,
  closeNew: PropTypes.func,
  startLoading: PropTypes.func.isRequired,
};

const FieldForm = ({
  merchantId,
  field,
  closeNew,
  startLoading,
}: InferProps<typeof propTypes>) => {
  const theme = useTheme();
  const createField = useCreateField();
  const updateField = useUpdateField();
  const deleteField = useDeleteField();

  const isNew = field.id === undefined;

  const validate = ({
    prompt,
  }: FormState) => {
    const errors: {
      prompt?: string,
    } = {};

    if (prompt === '') {
      errors.prompt = 'Field name required';
    }
    return errors;
  };

  const {
    values: {
      prompt,
    },
    errors: {
      prompt: promptError,
    },
    handleChange,
    handleBlur,
    handleSubmit,
  } = useFormik<FormState>({
    initialValues: {
      prompt: field.currentVersion.prompt,
    },
    validate,
    onSubmit: () => {
      if (field.isLocked) {
        return;
      }

      const newFieldVersion = new FieldVersion({
        createdAt: new Date(),
        fieldType: FieldType.Text,
        prompt,
      });

      if (isNew) {
        startLoading();
        createField(
          merchantId,
          field,
          newFieldVersion,
        );
        closeNew!();
      } else if (prompt !== field.currentVersion.prompt) {
        updateField(
          merchantId,
          field.id,
          newFieldVersion,
        );
      }
    },
  });

  const blurAndSubmit = (e: React.FocusEvent<any>) => {
    handleBlur(e);
    if (!field.isLocked && !isNew) {
      handleSubmit();
    }
  };

  const handleDelete = () => {
    if (!field.isLocked) {
      startLoading();
      deleteField(merchantId, field.id);
    }
  };

  const renderButton = () => {
    if (isNew) {
      return (
        <>
          <CheckButton onClick={handleSubmit} />
          <CancelButton onClick={closeNew!} />
        </>
      );
    }

    return field.isLocked ? (
      <IconItem
        text="Required"
        color={theme.palette.text.disabled}
        IconClass={LockOutlined}
      />
    ) : (
      <IconItem
        text="Remove"
        color={theme.palette.secondary.main}
        IconClass={DeleteIcon}
        onClick={handleDelete}
      />
    );
  };

  return (
    <TableRow isNew={isNew}>
      <td>
        <TextField
          name="prompt"
          label="Field name"
          value={prompt}
          helperText={promptError}
          onChange={handleChange}
          onBlur={blurAndSubmit}
          disabled={field.isLocked}
        />
      </td>
      <ButtonCell>{renderButton()}</ButtonCell>
    </TableRow>
  );
};

FieldForm.propTypes = propTypes;
FieldForm.defaultProps = {
  closeNew: null,
};

export default FieldForm;
