import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import CancelIcon from '@material-ui/icons/Cancel';
import SearchIcon from '@material-ui/icons/Search';
import { useFormik } from 'formik';
import PropTypes, { InferProps } from 'prop-types';
import React from 'react';

interface FormState {
  search?: string,
}

const propTypes = {
  isSearch: PropTypes.bool.isRequired,
  handleSearch: PropTypes.func.isRequired,
  clearSearch: PropTypes.func.isRequired,
};

const MerchantSearch = ({
  isSearch,
  handleSearch,
  clearSearch,
}: InferProps<typeof propTypes>) => {
  const validate = ({ search }: FormState) => {
    const errors: {
      search?: string,
    } = {};

    if (search === '') {
      errors.search = 'Search term required';
    }

    return errors;
  };

  const {
    values: {
      search,
    },
    errors: {
      search: searchError,
    },
    handleChange,
    handleSubmit,
    resetForm,
  } = useFormik<FormState>({
    initialValues: {
      search: '',
    },
    validate,
    onSubmit: (): Promise<void> => handleSearch(search!),
  });

  const handleClear = () => {
    clearSearch();
    resetForm();
  };

  const adornments: any = {
    startAdornment: (
      <InputAdornment position="start">
        <SearchIcon />
      </InputAdornment>
    ),
  };

  if (isSearch) {
    adornments.endAdornment = (
      <InputAdornment position="end">
        <IconButton onClick={handleClear}>
          <CancelIcon color="secondary" />
        </IconButton>
      </InputAdornment>
    );
  }

  return (
    <form onSubmit={handleSubmit}>
      <TextField
        name="search"
        placeholder="Search by region, merchant name, or ID"
        variant="outlined"
        value={search}
        onChange={handleChange}
        InputProps={adornments}
        helperText={searchError}
      />
    </form>
  );
};

MerchantSearch.propTypes = propTypes;

export default MerchantSearch;
