import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormLabel from '@material-ui/core/FormLabel';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import { useFormik } from 'formik';
import { FeeType, Merchant } from 'linebuster-types';
import some from 'lodash/some';
import toNumber from 'lodash/toNumber';
import PropTypes, { InferProps } from 'prop-types';
import React, { useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';
import styled from 'styled-components';

import {
  useCreateMerchant,
  useUpdateMerchant,
  useDeleteMerchant,
} from '../../../store/hooks';
import CheckButton from '../../lib/CheckButton';
import TableRow from '../../lib/TableRow';

interface FormState {
  name: string,
  cloverMid: string,
  serviceFeeCloverMid: string,
  serviceFeeValue: number,
  serviceFeeType: FeeType,
  customFieldsRequired: boolean
}
interface TableCellProps extends React.HTMLAttributes<HTMLAllCollection> {
  firstRow?: boolean,
}

const FIELD_NAMES = {
  name: 'name',
  cloverMid: 'cloverMid',
  serviceFeeCloverMid: 'serviceFeeCloverMid',
  serviceFeeValue: 'serviceFeeValue',
  serviceFeeType: 'serviceFeeType',
  customFieldsRequired: 'customFieldsRequired',
};

const StyledTableCell = styled.td<TableCellProps>`
  background-color: ${({ firstRow, theme }) => (firstRow ? theme.palette.grey[200] : 'transparent')};
  width: auto;
`;

const SeparatorTableCell = styled.td`
  min-width: 10px;
`;

const useStyles = makeStyles(() => ({
  root: {
    marginBottom: -15,
    marginLeft: 0,
    marginRight: 0,
  },
}));

const propTypes = {
  firstMerchantName: PropTypes.string,
  merchant: PropTypes.instanceOf(Merchant).isRequired,
  closeNew: PropTypes.func,
  regionId: PropTypes.string.isRequired,
};

const MerchantForm = ({
  firstMerchantName,
  merchant,
  closeNew,
  regionId,
}: InferProps<typeof propTypes>) => {
  const createMerchant = useCreateMerchant();
  const updateMerchant = useUpdateMerchant();
  const deleteMerchant = useDeleteMerchant();

  const isNew = merchant.id === undefined;

  const classes = useStyles();

  const validateField = (fieldName: string, values: FormState) => (
    {
      [FIELD_NAMES.name]: () => (
        values.name === '' ? 'Name required' : undefined
      ),
      [FIELD_NAMES.cloverMid]: () => (
        values.cloverMid === '' ? 'Merchant Clover MID required' : undefined
      ),
      [FIELD_NAMES.serviceFeeCloverMid]: () => (
        values.serviceFeeCloverMid === '' ? 'Service fee Clover MID required' : undefined
      ),
      [FIELD_NAMES.serviceFeeValue]: () => (
        values.serviceFeeValue == null && values.serviceFeeType !== FeeType.None ? 'Service fee value must be set' : undefined
      ),
      [FIELD_NAMES.serviceFeeType]: () => (
        ![FeeType.Amount, FeeType.Percent, FeeType.None].includes(values.serviceFeeType) ? 'Service fee type must be set' : undefined
      ),
    }[fieldName]()
  );

  const validate = (values: FormState) => {
    const errors: {
      name?: string,
      cloverMid?: string,
      serviceFeeCloverMid?: string,
      serviceFeeType?: string,
      serviceFeeValue?: string,
    } = {};
    const nameErr = validateField(FIELD_NAMES.name, values);
    const cloverMidErr = validateField(FIELD_NAMES.cloverMid, values);
    const serviceFeeCloverMidErr = validateField(FIELD_NAMES.serviceFeeCloverMid, values);
    const serviceFeeValueErr = validateField(FIELD_NAMES.serviceFeeValue, values);
    const serviceFeeTypeErr = validateField(FIELD_NAMES.serviceFeeType, values);

    if (nameErr) {
      errors.name = nameErr;
    }
    if (cloverMidErr) {
      errors.cloverMid = cloverMidErr;
    }
    if (serviceFeeCloverMidErr) {
      errors.serviceFeeCloverMid = serviceFeeCloverMidErr;
    }
    if (serviceFeeValueErr) {
      errors.serviceFeeValue = serviceFeeValueErr;
    }
    if (serviceFeeTypeErr) {
      errors.serviceFeeType = serviceFeeTypeErr;
    }
    return errors;
  };

  const {
    values,
    errors: {
      name: nameError,
      cloverMid: cloverMidError,
      serviceFeeCloverMid: serviceFeeCloverMidError,
      serviceFeeValue: serviceFeeValueError,
      serviceFeeType: serviceFeeTypeError,
    },
    handleBlur,
    handleSubmit,
    setFieldValue,
    setFieldError,
    setValues,
  } = useFormik<FormState>({
    enableReinitialize: true,
    initialValues: {
      name: merchant.name,
      cloverMid: merchant.cloverMid,
      serviceFeeCloverMid: merchant.serviceFeeCloverMid!,
      serviceFeeValue: merchant.serviceFeeValue!,
      serviceFeeType: merchant.serviceFeeType!,
      customFieldsRequired: merchant.customFieldsRequired!,
    },
    validate,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: async () => {
      const {
        name,
        cloverMid,
        serviceFeeCloverMid,
        serviceFeeValue,
        serviceFeeType,
        customFieldsRequired,
      } = values;

      const newMerchant = new Merchant({
        name,
        cloverMid,
        serviceFeeCloverMid,
        serviceFeeValue: toNumber(serviceFeeValue),
        serviceFeeType,
        customFieldsRequired,
      });

      if (isNew) {
        await createMerchant(newMerchant, regionId);
        closeNew!();
      } else {
        newMerchant.id = merchant.id;
        await updateMerchant(newMerchant);
      }
    },
  });

  useEffect(() => {
    setValues({
      name: merchant.name,
      cloverMid: merchant.cloverMid,
      serviceFeeCloverMid: merchant.serviceFeeCloverMid!,
      serviceFeeValue: merchant.serviceFeeValue!,
      serviceFeeType: merchant.serviceFeeType!,
      customFieldsRequired: merchant.customFieldsRequired!,
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [merchant]);

  const [submitNewDisabled, setSubmitNewDisabled] = useState(true);
  useEffect(() => {
    const errs = [
      validateField(FIELD_NAMES.name, values),
      validateField(FIELD_NAMES.cloverMid, values),
      validateField(FIELD_NAMES.serviceFeeCloverMid, values),
      validateField(FIELD_NAMES.serviceFeeValue, values),
      validateField(FIELD_NAMES.serviceFeeType, values),
    ];
    setSubmitNewDisabled(some(errs));
  }, [values, setSubmitNewDisabled]);

  const getHandleChange = (fieldName: string) => (
    { target: { value } }: React.ChangeEvent<any>,
  ) => {
    setFieldValue(fieldName, value);
    const error = validateField(fieldName, values);
    setFieldError(fieldName, error || undefined);
  };

  const getBlurAndSubmit = (fieldName: string) => (e: React.FocusEvent<any>) => {
    handleBlur(e);
    const error = validateField(fieldName, values);
    setFieldError(fieldName, error || undefined);
    if (!isNew && !error) {
      handleSubmit();
    }
  };

  const handleServiceFeeTypeChanged = ({ target: { value } }: React.ChangeEvent<any>) => {
    if (value === FeeType.None) {
      setFieldValue(FIELD_NAMES.serviceFeeValue, 0);
    }
    setFieldValue(FIELD_NAMES.serviceFeeType, value);
    const error = validateField(FIELD_NAMES.serviceFeeType, values);
    setFieldError(FIELD_NAMES.serviceFeeType, error || undefined);
    if (!isNew && !error) {
      handleSubmit();
    }
  };

  const handleCustomFieldsRequiredSwitchChanged = ({ target: { checked } }:
     React.ChangeEvent<any>) => {
    setFieldValue(FIELD_NAMES.customFieldsRequired, checked);
    handleSubmit();
  };

  const handleDelete = () => deleteMerchant(merchant.id);

  const {
    name,
    cloverMid,
    serviceFeeCloverMid,
    serviceFeeValue,
    serviceFeeType,
    customFieldsRequired,
  } = values;

  const isFirstRow = name === firstMerchantName && !isNew;

  return (
    <TableRow isNew={isNew}>
      <StyledTableCell>
        <TextField
          name={FIELD_NAMES.name}
          label="Merchant name"
          value={name}
          helperText={nameError}
          onChange={getHandleChange(FIELD_NAMES.name)}
          onBlur={getBlurAndSubmit(FIELD_NAMES.name)}
        />
      </StyledTableCell>
      <StyledTableCell>
        <TextField
          name={FIELD_NAMES.cloverMid}
          label="Primary MID"
          value={cloverMid}
          helperText={cloverMidError}
          onChange={getHandleChange(FIELD_NAMES.cloverMid)}
          onBlur={getBlurAndSubmit(FIELD_NAMES.cloverMid)}
        />
      </StyledTableCell>
      <StyledTableCell firstRow={isFirstRow}>
        <TextField
          name={FIELD_NAMES.serviceFeeCloverMid}
          label="Service fee MID"
          value={serviceFeeCloverMid}
          helperText={serviceFeeCloverMidError}
          onChange={getHandleChange(FIELD_NAMES.serviceFeeCloverMid)}
          onBlur={getBlurAndSubmit(FIELD_NAMES.serviceFeeCloverMid)}
        />
      </StyledTableCell>
      <StyledTableCell firstRow={isFirstRow}>
        <NumberFormat
          decimalScale={2}
          fixedDecimalScale
          allowNegative={false}
          name={FIELD_NAMES.serviceFeeValue}
          allowEmptyFormatting
          label="Service fee value"
          value={serviceFeeValue}
          helperText={serviceFeeValueError}
          onChange={getHandleChange(FIELD_NAMES.serviceFeeValue)}
          onBlur={getBlurAndSubmit(FIELD_NAMES.serviceFeeValue)}
          disabled={serviceFeeType === FeeType.None}
          InputProps={{
            startAdornment: serviceFeeType === FeeType.Amount && <InputAdornment position="start">$</InputAdornment>,
            endAdornment: serviceFeeType === FeeType.Percent && <InputAdornment position="end">%</InputAdornment>,
          }}
          customInput={TextField}
        />
      </StyledTableCell>
      <StyledTableCell firstRow={isFirstRow}>
        <FormControl component="fieldset" fullWidth>
          <FormLabel component="legend">Service fee type</FormLabel>
          <RadioGroup
            name={FIELD_NAMES.serviceFeeType}
            value={serviceFeeType}
            onChange={handleServiceFeeTypeChanged}
            onBlur={handleBlur}
            row
          >
            <FormControlLabel
              value={FeeType.Amount}
              label="Amount"
              control={<Radio />}
            />
            <FormControlLabel
              value={FeeType.Percent}
              label="Percent"
              control={<Radio />}
            />
            <FormControlLabel
              value="none"
              label="None"
              control={<Radio />}
            />
          </RadioGroup>
          <FormHelperText>{serviceFeeTypeError}</FormHelperText>
        </FormControl>
      </StyledTableCell>
      <SeparatorTableCell />
      <StyledTableCell firstRow={isFirstRow}>
        <FormControlLabel
          classes={classes}
          labelPlacement="start"
          label="Show Custom Fields"
          checked={customFieldsRequired}
          onChange={handleCustomFieldsRequiredSwitchChanged}
          control={(<Switch />)}
        />
      </StyledTableCell>
      <StyledTableCell>
        {isNew ? (
          <CheckButton
            onClick={handleSubmit}
            disabled={submitNewDisabled}
          />
        ) : (
          <IconButton classes={classes} onClick={handleDelete}>
            <DeleteIcon />
          </IconButton>
        )}
      </StyledTableCell>
    </TableRow>
  );
};

MerchantForm.propTypes = propTypes;
MerchantForm.defaultProps = {
  closeNew: null,
  firstMerchantName: '',
};

export default MerchantForm;
