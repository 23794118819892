import { createReducer } from '@reduxjs/toolkit';
import { FieldVersion } from 'linebuster-types';
import omit from 'lodash/omit';

import {
  getFields,
  createFieldVersion,
  createField,
  deleteField,
} from './actions';
import { FieldsState } from './interfaces';

const fieldsReducer = createReducer<FieldsState>({}, (builder) => {
  builder
    .addCase(
      getFields.fulfilled,
      (state, { payload: { fields } }) => ({
        ...state,
        ...fields,
      }),
    )
    .addCase(
      createField.fulfilled,
      (state, { payload: { field } }) => ({
        ...state,
        [field.id]: field,
      }),
    )
    .addCase(
      createFieldVersion.fulfilled,
      (state, { payload: { fieldId, currentVersion } }) => ({
        ...state,
        [fieldId]: {
          ...state[fieldId],
          currentVersion: currentVersion as FieldVersion,
        },
      }),
    )
    .addCase(
      deleteField.fulfilled,
      (state, { payload: { fieldId } }) => omit(state, fieldId),
    );
});

export default fieldsReducer;
