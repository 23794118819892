import { AsyncThunkAction, PayloadAction } from '@reduxjs/toolkit';
import {
  getRepository,
  CollectionId,
  Export,
  Field,
  FieldVersion,
  Merchant,
  Region,
  UserRepository,
  UserEntry,
  User,
} from 'linebuster-types';
import differenceBy from 'lodash/differenceBy';
import { DateTime } from 'luxon';
import { useContext, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { UserContext } from '../components/contexts';
import {
  getMerchants,
  getMerchantsForRegion,
  deleteMerchant,
  createMerchant,
  updateMerchant,
  createUser,
  updateUser,
  deleteUser,
  getFields,
  createFieldVersion,
  createField,
  deleteField,
  getUsers,
  assignMerchants,
  setPassword,
  forgotPassword,
  getRegions,
  getRegionsByIds,
  createRegion,
  updateRegion,
  deleteRegion,
  getMostRecentExport,
  createExport,
  observeExport,
  setAlert,
} from './actions';
import store, {
  merchantsSelector,
  merchantSelector,
  merchantFieldsSelector,
  mostRecentExportSelector,
  regionsSelector,
  usersSelector,
  regionSelector,
  regionsLoadedSelector,
  regionsPendingSelector,
  merchantsLoadedSelector,
  merchantsPendingSelector,
  usersLoadedSelector,
  usersPendingSelector,
  alertSelector,
  AppDispatch,
} from './index';
import {
  AlertState,

  FieldsState,
  MerchantsState,
  RegionsState,
  UsersState,
} from './interfaces';

export function usePrevious<T>(value: T): T | undefined {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

/**
 * Provides a correctly-typed dispatch function.
 */
const useAppDispatch = () => useDispatch<typeof store.dispatch>();

export function useRegionIdParam() {
  const params: { [key: string]: string } = useParams();
  return params.regionId || null;
}

export function useUserRepository() {
  return getRepository<UserRepository>(CollectionId.Users);
}

export function useUsers(): [
  UserEntry[],
  (lastId: string | null) => AsyncThunkAction<
    { users: UsersState },
    { lastId: string | null },
    {}
  >] {
  const dispatch = useDispatch();
  const users = useSelector(usersSelector);
  return [
    Object.values(users),
    (lastId: string | null) => dispatch(getUsers({ lastId })),
  ];
}

export function useCreateUser() {
  const dispatch = useAppDispatch();
  return (user: UserEntry, ids: string[]) => dispatch(
    createUser({ user: new UserEntry(user), ids }),
  );
}

export function useAssignMerchants() {
  const dispatch = useDispatch();
  return (ids: string[], manager: any) => dispatch(assignMerchants({ ids, manager }));
}

export function useUpdateUser() {
  const dispatch = useDispatch();
  return (user: UserEntry) => dispatch(updateUser({ user: new UserEntry(user) }));
}

export function useSetPassword() {
  const dispatch: AppDispatch = useDispatch();
  return (token?: string, password?: string) => dispatch(setPassword({ token, password }));
}

export function useForgotPassword() {
  const dispatch: AppDispatch = useDispatch();
  return (email?: string) => dispatch(forgotPassword({ email }));
}

export function useRegions(): [
  Region[],
  (lastId: string | null) => AsyncThunkAction<
    { regions: RegionsState },
    { lastId: string | null },
    {}
  >] {
  const dispatch = useDispatch();
  const regions = useSelector(regionsSelector);
  return [
    Object.values(regions),
    (lastId: string | null) => dispatch(getRegions({ lastId })),
  ];
}

export function useRegionsByIds(): [
  Region[],
  (ids: string[]) => AsyncThunkAction<
    {},
    { ids: string[] },
    {}
  >] {
  const dispatch = useDispatch();
  const regions = useSelector(regionsSelector);
  return [
    Object.values(regions),
    (ids: string[]) => dispatch(getRegionsByIds({ ids })),
  ];
}

export function useRegion(regionId: string | null) {
  return useSelector(regionSelector(regionId));
}

export function useCreatedRegionId() {
  const [regions] = useRegions();
  const prevRegions = usePrevious(regions);
  if (prevRegions === undefined || regions.length === prevRegions.length) {
    return null;
  }

  const createdRegions = differenceBy(regions, prevRegions, 'id');
  return createdRegions[0].id;
}

export function useCreateRegion() {
  const dispatch = useDispatch();
  return (region: Region) => dispatch(createRegion({ region }));
}

export function useUpdateRegion() {
  const dispatch = useDispatch();
  return (region: Region) => dispatch(updateRegion({ region }));
}

export function useDeleteRegion() {
  const dispatch = useDispatch();
  return (regionId: string) => dispatch(deleteRegion({ regionId }));
}

export function useMerchants(): [
  Merchant[],
  () => AsyncThunkAction<{ merchants: MerchantsState }, User, {}>,
  ] {
  const dispatch = useDispatch();
  const { user } = useContext(UserContext);
  const merchants = useSelector(merchantsSelector);
  return [Object.values(merchants), () => dispatch(getMerchants(user!))];
}

export function useMerchantsForRegion(): [
  Merchant[],
  (regionId: string, lastId: string | null) => Promise<PayloadAction<any>>
  ] {
  const dispatch = useAppDispatch();
  const merchants = useSelector(merchantsSelector);
  return [
    Object.values(merchants),
    (regionId: string, lastId: string | null) => dispatch(
      getMerchantsForRegion({ regionId, lastId }),
    ),
  ];
}

export function useMerchant(merchantId: string) {
  return useSelector(merchantSelector(merchantId));
}

export function useCreateMerchant() {
  const dispatch = useAppDispatch();
  return (merchant: Merchant, regionId: string) => dispatch(createMerchant({ merchant, regionId }));
}

export function useUpdateMerchant() {
  const dispatch = useAppDispatch();
  return (merchant: Merchant) => dispatch(updateMerchant({ merchant }));
}

export function useDeleteUser() {
  const dispatch = useDispatch();
  return (userId: string) => dispatch(deleteUser({ userId }));
}

export function useDeleteMerchant() {
  const dispatch = useAppDispatch();
  return (merchantId: string) => dispatch(deleteMerchant({ merchantId }));
}

export function useFields(merchantId: string): [
  Field[] | null,
  () => AsyncThunkAction<{
    merchantId: string,
    fields: FieldsState
  }, { merchantId: string }, {}>,
] {
  const dispatch = useDispatch();
  const merchantFields = useSelector(merchantFieldsSelector(merchantId));
  return [
    merchantFields ? Object.values(merchantFields) : null,
    () => dispatch(getFields({ merchantId })),
  ];
}

export function useCreateField() {
  const dispatch = useDispatch();
  return (
    merchantId: string,
    field: Field,
    fieldVersion: FieldVersion,
  ) => dispatch(createField({
    merchantId,
    field,
    fieldVersion,
  }));
}

export function useUpdateField() {
  const dispatch = useDispatch();
  return (
    merchantId: string,
    fieldId: string,
    fieldVersion: FieldVersion,
  ) => dispatch(createFieldVersion({
    merchantId,
    fieldId,
    fieldVersion,
  }));
}

export function useDeleteField() {
  const dispatch = useDispatch();
  return (
    merchantId: string,
    fieldId: string,
  ) => dispatch(deleteField({
    merchantId,
    fieldId,
  }));
}

export function useMostRecentExport(userId: string, merchantId: string | null): [
  Export | null,
  () => AsyncThunkAction<
    { export: { [key: string]: any } } | null,
    { userId: string, merchantId: string | null },
    {}
  >,
] {
  const dispatch = useDispatch();
  const exp = useSelector(mostRecentExportSelector(userId, merchantId));
  return [
    exp,
    () => dispatch(getMostRecentExport({ userId, merchantId })),
  ];
}

export function useCreateExport() {
  const dispatch = useDispatch();
  return (
    userId: string,
    merchantId: string | null,
    startDate: DateTime,
    endDate: DateTime,
  ) => dispatch(createExport({
    userId,
    merchantId,
    startDate,
    endDate,
  }));
}

export function useObserveExport() {
  const dispatch = useDispatch();
  return (id: string) => dispatch(observeExport(id));
}

export function useRegionsLoaded() {
  return useSelector(regionsLoadedSelector);
}

export function useRegionsPending() {
  return useSelector(regionsPendingSelector);
}

export function useMerchantsLoaded() {
  return useSelector(merchantsLoadedSelector);
}

export function useMerchantsPending() {
  return useSelector(merchantsPendingSelector);
}

export function useUsersLoaded() {
  return useSelector(usersLoadedSelector);
}

export function useUsersPending() {
  return useSelector(usersPendingSelector);
}

export function useSetAlert(): [AlertState, Function] {
  const alert = useSelector(alertSelector);
  const dispatch = useDispatch();
  return [
    alert,
    ({
      message = alert.message,
      severity = alert.severity,
      visible = alert.visible,
    }: AlertState) => dispatch(setAlert({ message, severity, visible })),
  ];
}

export function useSetSuccess() {
  const dispatch = useDispatch();
  return (message: string) => dispatch(setAlert({ message, severity: 'success', visible: true }));
}

export function useSetError() {
  const dispatch = useDispatch();
  return (message: string) => dispatch(setAlert({ message, severity: 'error', visible: true }));
}
