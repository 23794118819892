/* eslint-disable no-console */
import { logFn } from './functions';

const functionName = 'linebuster-web';
const logName = 'linebuster-web';

const logger = {
  info: (data: any) => {
    if (!process.env.REACT_APP_USE_EMULATOR) {
      logFn({
        functionName, logName, severity: 'INFO', data,
      });
    }
    console.log(data);
  },
  error: (data: any) => {
    if (!process.env.REACT_APP_USE_EMULATOR) {
      logFn({
        functionName, logName, severity: 'ERROR', data,
      });
    }
    console.error(data);
  },
};

export default logger;
